import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { filter, map, throttleTime } from 'rxjs/operators';

// eslint-disable-next-line @angular-eslint/prefer-standalone
@Component({
  selector: 'app-search',
  template: `
  <div class="search">
    <div class="search__query">
      <label class="search__label" for="search">Search</label>
      <input class="search__field"
             type="text"
             id="search"
             [formControl]="term"
             placeholder="Search"
             autocomplete="off">
    </div>
    <div class="search__answer">
      <ng-container *ngFor="let result of results">
        <a [routerLink]="result.slice(0,1)" [fragment]="result" (click)="clear()">{{result}}</a>
      </ng-container>
    </div>
  </div>
`,
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class SearchComponent implements OnInit {
  term = new UntypedFormControl('');
  results = [];
  icons = [
    'emoji_u_0023_20_e_3',
    'emoji_u_0023',
    'emoji_u_002_a_20_e_3',
    'emoji_u_002_a',
    'emoji_u_0030_20_e_3',
    'emoji_u_0030',
    'emoji_u_0031_20_e_3',
    'emoji_u_0031',
    'emoji_u_0032_20_e_3',
    'emoji_u_0032',
    'emoji_u_0033_20_e_3',
    'emoji_u_0033',
    'emoji_u_0034_20_e_3',
    'emoji_u_0034',
    'emoji_u_0035_20_e_3',
    'emoji_u_0035',
    'emoji_u_0036_20_e_3',
    'emoji_u_0036',
    'emoji_u_0037_20_e_3',
    'emoji_u_0037',
    'emoji_u_0038_20_e_3',
    'emoji_u_0038',
    'emoji_u_0039_20_e_3',
    'emoji_u_0039',
    'emoji_u_00_a_9',
    'emoji_u_00_ae',
    'emoji_u_1_f_004',
    'emoji_u_1_f_0_cf',
    'emoji_u_1_f_170',
    'emoji_u_1_f_171',
    'emoji_u_1_f_17_e',
    'emoji_u_1_f_17_f',
    'emoji_u_1_f_18_e',
    'emoji_u_1_f_191',
    'emoji_u_1_f_192',
    'emoji_u_1_f_193',
    'emoji_u_1_f_194',
    'emoji_u_1_f_195',
    'emoji_u_1_f_196',
    'emoji_u_1_f_197',
    'emoji_u_1_f_198',
    'emoji_u_1_f_199',
    'emoji_u_1_f_19_a',
    'emoji_u_1_f_1_e_6',
    'emoji_u_1_f_1_e_7',
    'emoji_u_1_f_1_e_8',
    'emoji_u_1_f_1_e_9',
    'emoji_u_1_f_1_ea',
    'emoji_u_1_f_1_eb',
    'emoji_u_1_f_1_ec',
    'emoji_u_1_f_1_ed',
    'emoji_u_1_f_1_ee',
    'emoji_u_1_f_1_ef',
    'emoji_u_1_f_1_f_0',
    'emoji_u_1_f_1_f_1',
    'emoji_u_1_f_1_f_2',
    'emoji_u_1_f_1_f_3',
    'emoji_u_1_f_1_f_4',
    'emoji_u_1_f_1_f_5',
    'emoji_u_1_f_1_f_6',
    'emoji_u_1_f_1_f_7',
    'emoji_u_1_f_1_f_8',
    'emoji_u_1_f_1_f_9',
    'emoji_u_1_f_1_fa',
    'emoji_u_1_f_1_fb',
    'emoji_u_1_f_1_fc',
    'emoji_u_1_f_1_fd',
    'emoji_u_1_f_1_fe',
    'emoji_u_1_f_1_ff',
    'emoji_u_1_f_201',
    'emoji_u_1_f_202',
    'emoji_u_1_f_21_a',
    'emoji_u_1_f_22_f',
    'emoji_u_1_f_232',
    'emoji_u_1_f_233',
    'emoji_u_1_f_234',
    'emoji_u_1_f_235',
    'emoji_u_1_f_236',
    'emoji_u_1_f_237',
    'emoji_u_1_f_238',
    'emoji_u_1_f_239',
    'emoji_u_1_f_23_a',
    'emoji_u_1_f_250',
    'emoji_u_1_f_251',
    'emoji_u_1_f_300',
    'emoji_u_1_f_301',
    'emoji_u_1_f_302',
    'emoji_u_1_f_303',
    'emoji_u_1_f_304',
    'emoji_u_1_f_305',
    'emoji_u_1_f_306',
    'emoji_u_1_f_307',
    'emoji_u_1_f_308',
    'emoji_u_1_f_309',
    'emoji_u_1_f_30_a',
    'emoji_u_1_f_30_b',
    'emoji_u_1_f_30_c',
    'emoji_u_1_f_30_d',
    'emoji_u_1_f_30_e',
    'emoji_u_1_f_30_f',
    'emoji_u_1_f_310',
    'emoji_u_1_f_311',
    'emoji_u_1_f_312',
    'emoji_u_1_f_313',
    'emoji_u_1_f_314',
    'emoji_u_1_f_315',
    'emoji_u_1_f_316',
    'emoji_u_1_f_317',
    'emoji_u_1_f_318',
    'emoji_u_1_f_319',
    'emoji_u_1_f_31_a',
    'emoji_u_1_f_31_b',
    'emoji_u_1_f_31_c',
    'emoji_u_1_f_31_d',
    'emoji_u_1_f_31_e',
    'emoji_u_1_f_31_f',
    'emoji_u_1_f_320',
    'emoji_u_1_f_321',
    'emoji_u_1_f_324',
    'emoji_u_1_f_325',
    'emoji_u_1_f_326',
    'emoji_u_1_f_327',
    'emoji_u_1_f_328',
    'emoji_u_1_f_329',
    'emoji_u_1_f_32_a',
    'emoji_u_1_f_32_b',
    'emoji_u_1_f_32_c',
    'emoji_u_1_f_32_d',
    'emoji_u_1_f_32_e',
    'emoji_u_1_f_32_f',
    'emoji_u_1_f_330',
    'emoji_u_1_f_331',
    'emoji_u_1_f_332',
    'emoji_u_1_f_333',
    'emoji_u_1_f_334',
    'emoji_u_1_f_335',
    'emoji_u_1_f_336',
    'emoji_u_1_f_337',
    'emoji_u_1_f_338',
    'emoji_u_1_f_339',
    'emoji_u_1_f_33_a',
    'emoji_u_1_f_33_b',
    'emoji_u_1_f_33_c',
    'emoji_u_1_f_33_d',
    'emoji_u_1_f_33_e',
    'emoji_u_1_f_33_f',
    'emoji_u_1_f_340',
    'emoji_u_1_f_341',
    'emoji_u_1_f_342',
    'emoji_u_1_f_343',
    'emoji_u_1_f_344',
    'emoji_u_1_f_345',
    'emoji_u_1_f_346',
    'emoji_u_1_f_347',
    'emoji_u_1_f_348',
    'emoji_u_1_f_349',
    'emoji_u_1_f_34_a',
    'emoji_u_1_f_34_b',
    'emoji_u_1_f_34_c',
    'emoji_u_1_f_34_d',
    'emoji_u_1_f_34_e',
    'emoji_u_1_f_34_f',
    'emoji_u_1_f_350',
    'emoji_u_1_f_351',
    'emoji_u_1_f_352',
    'emoji_u_1_f_353',
    'emoji_u_1_f_354',
    'emoji_u_1_f_355',
    'emoji_u_1_f_356',
    'emoji_u_1_f_357',
    'emoji_u_1_f_358',
    'emoji_u_1_f_359',
    'emoji_u_1_f_35_a',
    'emoji_u_1_f_35_b',
    'emoji_u_1_f_35_c',
    'emoji_u_1_f_35_d',
    'emoji_u_1_f_35_e',
    'emoji_u_1_f_35_f',
    'emoji_u_1_f_360',
    'emoji_u_1_f_361',
    'emoji_u_1_f_362',
    'emoji_u_1_f_363',
    'emoji_u_1_f_364',
    'emoji_u_1_f_365',
    'emoji_u_1_f_366',
    'emoji_u_1_f_367',
    'emoji_u_1_f_368',
    'emoji_u_1_f_369',
    'emoji_u_1_f_36_a',
    'emoji_u_1_f_36_b',
    'emoji_u_1_f_36_c',
    'emoji_u_1_f_36_d',
    'emoji_u_1_f_36_e',
    'emoji_u_1_f_36_f',
    'emoji_u_1_f_370',
    'emoji_u_1_f_371',
    'emoji_u_1_f_372',
    'emoji_u_1_f_373',
    'emoji_u_1_f_374',
    'emoji_u_1_f_375',
    'emoji_u_1_f_376',
    'emoji_u_1_f_377',
    'emoji_u_1_f_378',
    'emoji_u_1_f_379',
    'emoji_u_1_f_37_a',
    'emoji_u_1_f_37_b',
    'emoji_u_1_f_37_c',
    'emoji_u_1_f_37_d',
    'emoji_u_1_f_37_e',
    'emoji_u_1_f_37_f',
    'emoji_u_1_f_380',
    'emoji_u_1_f_381',
    'emoji_u_1_f_382',
    'emoji_u_1_f_383',
    'emoji_u_1_f_384',
    'emoji_u_1_f_385_1_f_3_fb',
    'emoji_u_1_f_385_1_f_3_fc',
    'emoji_u_1_f_385_1_f_3_fd',
    'emoji_u_1_f_385_1_f_3_fe',
    'emoji_u_1_f_385_1_f_3_ff',
    'emoji_u_1_f_385',
    'emoji_u_1_f_386',
    'emoji_u_1_f_387',
    'emoji_u_1_f_388',
    'emoji_u_1_f_389',
    'emoji_u_1_f_38_a',
    'emoji_u_1_f_38_b',
    'emoji_u_1_f_38_c',
    'emoji_u_1_f_38_d',
    'emoji_u_1_f_38_e',
    'emoji_u_1_f_38_f',
    'emoji_u_1_f_390',
    'emoji_u_1_f_391',
    'emoji_u_1_f_392',
    'emoji_u_1_f_393',
    'emoji_u_1_f_396',
    'emoji_u_1_f_397',
    'emoji_u_1_f_399',
    'emoji_u_1_f_39_a',
    'emoji_u_1_f_39_b',
    'emoji_u_1_f_39_e',
    'emoji_u_1_f_39_f',
    'emoji_u_1_f_3_a_0',
    'emoji_u_1_f_3_a_1',
    'emoji_u_1_f_3_a_2',
    'emoji_u_1_f_3_a_3',
    'emoji_u_1_f_3_a_4',
    'emoji_u_1_f_3_a_5',
    'emoji_u_1_f_3_a_6',
    'emoji_u_1_f_3_a_7',
    'emoji_u_1_f_3_a_8',
    'emoji_u_1_f_3_a_9',
    'emoji_u_1_f_3_aa',
    'emoji_u_1_f_3_ab',
    'emoji_u_1_f_3_ac',
    'emoji_u_1_f_3_ad',
    'emoji_u_1_f_3_ae',
    'emoji_u_1_f_3_af',
    'emoji_u_1_f_3_b_0',
    'emoji_u_1_f_3_b_1',
    'emoji_u_1_f_3_b_2',
    'emoji_u_1_f_3_b_3',
    'emoji_u_1_f_3_b_4',
    'emoji_u_1_f_3_b_5',
    'emoji_u_1_f_3_b_6',
    'emoji_u_1_f_3_b_7',
    'emoji_u_1_f_3_b_8',
    'emoji_u_1_f_3_b_9',
    'emoji_u_1_f_3_ba',
    'emoji_u_1_f_3_bb',
    'emoji_u_1_f_3_bc',
    'emoji_u_1_f_3_bd',
    'emoji_u_1_f_3_be',
    'emoji_u_1_f_3_bf',
    'emoji_u_1_f_3_c_0',
    'emoji_u_1_f_3_c_1',
    'emoji_u_1_f_3_c_2_1_f_3_fb',
    'emoji_u_1_f_3_c_2_1_f_3_fc',
    'emoji_u_1_f_3_c_2_1_f_3_fd',
    'emoji_u_1_f_3_c_2_1_f_3_fe',
    'emoji_u_1_f_3_c_2_1_f_3_ff',
    'emoji_u_1_f_3_c_2',
    'emoji_u_1_f_3_c_3_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_3_c_3_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_3_c_3_1_f_3_fb',
    'emoji_u_1_f_3_c_3_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_3_c_3_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_3_c_3_1_f_3_fc',
    'emoji_u_1_f_3_c_3_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_3_c_3_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_3_c_3_1_f_3_fd',
    'emoji_u_1_f_3_c_3_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_3_c_3_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_3_c_3_1_f_3_fe',
    'emoji_u_1_f_3_c_3_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_3_c_3_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_3_c_3_1_f_3_ff',
    'emoji_u_1_f_3_c_3_200_d_2640',
    'emoji_u_1_f_3_c_3_200_d_2642',
    'emoji_u_1_f_3_c_3',
    'emoji_u_1_f_3_c_4_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_3_c_4_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_3_c_4_1_f_3_fb',
    'emoji_u_1_f_3_c_4_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_3_c_4_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_3_c_4_1_f_3_fc',
    'emoji_u_1_f_3_c_4_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_3_c_4_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_3_c_4_1_f_3_fd',
    'emoji_u_1_f_3_c_4_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_3_c_4_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_3_c_4_1_f_3_fe',
    'emoji_u_1_f_3_c_4_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_3_c_4_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_3_c_4_1_f_3_ff',
    'emoji_u_1_f_3_c_4_200_d_2640',
    'emoji_u_1_f_3_c_4_200_d_2642',
    'emoji_u_1_f_3_c_4',
    'emoji_u_1_f_3_c_5',
    'emoji_u_1_f_3_c_6',
    'emoji_u_1_f_3_c_7_1_f_3_fb',
    'emoji_u_1_f_3_c_7_1_f_3_fc',
    'emoji_u_1_f_3_c_7_1_f_3_fd',
    'emoji_u_1_f_3_c_7_1_f_3_fe',
    'emoji_u_1_f_3_c_7_1_f_3_ff',
    'emoji_u_1_f_3_c_7',
    'emoji_u_1_f_3_c_8',
    'emoji_u_1_f_3_c_9',
    'emoji_u_1_f_3_ca_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_3_ca_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_3_ca_1_f_3_fb',
    'emoji_u_1_f_3_ca_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_3_ca_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_3_ca_1_f_3_fc',
    'emoji_u_1_f_3_ca_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_3_ca_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_3_ca_1_f_3_fd',
    'emoji_u_1_f_3_ca_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_3_ca_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_3_ca_1_f_3_fe',
    'emoji_u_1_f_3_ca_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_3_ca_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_3_ca_1_f_3_ff',
    'emoji_u_1_f_3_ca_200_d_2640',
    'emoji_u_1_f_3_ca_200_d_2642',
    'emoji_u_1_f_3_ca',
    'emoji_u_1_f_3_cb_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_3_cb_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_3_cb_1_f_3_fb',
    'emoji_u_1_f_3_cb_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_3_cb_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_3_cb_1_f_3_fc',
    'emoji_u_1_f_3_cb_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_3_cb_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_3_cb_1_f_3_fd',
    'emoji_u_1_f_3_cb_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_3_cb_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_3_cb_1_f_3_fe',
    'emoji_u_1_f_3_cb_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_3_cb_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_3_cb_1_f_3_ff',
    'emoji_u_1_f_3_cb_200_d_2640',
    'emoji_u_1_f_3_cb_200_d_2642',
    'emoji_u_1_f_3_cb',
    'emoji_u_1_f_3_cc_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_3_cc_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_3_cc_1_f_3_fb',
    'emoji_u_1_f_3_cc_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_3_cc_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_3_cc_1_f_3_fc',
    'emoji_u_1_f_3_cc_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_3_cc_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_3_cc_1_f_3_fd',
    'emoji_u_1_f_3_cc_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_3_cc_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_3_cc_1_f_3_fe',
    'emoji_u_1_f_3_cc_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_3_cc_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_3_cc_1_f_3_ff',
    'emoji_u_1_f_3_cc_200_d_2640',
    'emoji_u_1_f_3_cc_200_d_2642',
    'emoji_u_1_f_3_cc',
    'emoji_u_1_f_3_cd',
    'emoji_u_1_f_3_ce',
    'emoji_u_1_f_3_cf',
    'emoji_u_1_f_3_d_0',
    'emoji_u_1_f_3_d_1',
    'emoji_u_1_f_3_d_2',
    'emoji_u_1_f_3_d_3',
    'emoji_u_1_f_3_d_4',
    'emoji_u_1_f_3_d_5',
    'emoji_u_1_f_3_d_6',
    'emoji_u_1_f_3_d_7',
    'emoji_u_1_f_3_d_8',
    'emoji_u_1_f_3_d_9',
    'emoji_u_1_f_3_da',
    'emoji_u_1_f_3_db',
    'emoji_u_1_f_3_dc',
    'emoji_u_1_f_3_dd',
    'emoji_u_1_f_3_de',
    'emoji_u_1_f_3_df',
    'emoji_u_1_f_3_e_0',
    'emoji_u_1_f_3_e_1',
    'emoji_u_1_f_3_e_2',
    'emoji_u_1_f_3_e_3',
    'emoji_u_1_f_3_e_4',
    'emoji_u_1_f_3_e_5',
    'emoji_u_1_f_3_e_6',
    'emoji_u_1_f_3_e_7',
    'emoji_u_1_f_3_e_8',
    'emoji_u_1_f_3_e_9',
    'emoji_u_1_f_3_ea',
    'emoji_u_1_f_3_eb',
    'emoji_u_1_f_3_ec',
    'emoji_u_1_f_3_ed',
    'emoji_u_1_f_3_ee',
    'emoji_u_1_f_3_ef',
    'emoji_u_1_f_3_f_0',
    'emoji_u_1_f_3_f_3_200_d_1_f_308',
    'emoji_u_1_f_3_f_3',
    'emoji_u_1_f_3_f_4_200_d_2620',
    'emoji_u_1_f_3_f_4',
    'emoji_u_1_f_3_f_5',
    'emoji_u_1_f_3_f_7',
    'emoji_u_1_f_3_f_8',
    'emoji_u_1_f_3_f_9',
    'emoji_u_1_f_3_fa',
    'emoji_u_1_f_3_fb',
    'emoji_u_1_f_3_fc',
    'emoji_u_1_f_3_fd',
    'emoji_u_1_f_3_fe',
    'emoji_u_1_f_3_ff',
    'emoji_u_1_f_400',
    'emoji_u_1_f_401',
    'emoji_u_1_f_402',
    'emoji_u_1_f_403',
    'emoji_u_1_f_404',
    'emoji_u_1_f_405',
    'emoji_u_1_f_406',
    'emoji_u_1_f_407',
    'emoji_u_1_f_408',
    'emoji_u_1_f_409',
    'emoji_u_1_f_40_a',
    'emoji_u_1_f_40_b',
    'emoji_u_1_f_40_c',
    'emoji_u_1_f_40_d',
    'emoji_u_1_f_40_e',
    'emoji_u_1_f_40_f',
    'emoji_u_1_f_410',
    'emoji_u_1_f_411',
    'emoji_u_1_f_412',
    'emoji_u_1_f_413',
    'emoji_u_1_f_414',
    'emoji_u_1_f_415_200_d_1_f_9_ba',
    'emoji_u_1_f_415',
    'emoji_u_1_f_416',
    'emoji_u_1_f_417',
    'emoji_u_1_f_418',
    'emoji_u_1_f_419',
    'emoji_u_1_f_41_a',
    'emoji_u_1_f_41_b',
    'emoji_u_1_f_41_c',
    'emoji_u_1_f_41_d',
    'emoji_u_1_f_41_e',
    'emoji_u_1_f_41_f',
    'emoji_u_1_f_420',
    'emoji_u_1_f_421',
    'emoji_u_1_f_422',
    'emoji_u_1_f_423',
    'emoji_u_1_f_424',
    'emoji_u_1_f_425',
    'emoji_u_1_f_426',
    'emoji_u_1_f_427',
    'emoji_u_1_f_428',
    'emoji_u_1_f_429',
    'emoji_u_1_f_42_a',
    'emoji_u_1_f_42_b',
    'emoji_u_1_f_42_c',
    'emoji_u_1_f_42_d',
    'emoji_u_1_f_42_e',
    'emoji_u_1_f_42_f',
    'emoji_u_1_f_430',
    'emoji_u_1_f_431',
    'emoji_u_1_f_432',
    'emoji_u_1_f_433',
    'emoji_u_1_f_434',
    'emoji_u_1_f_435',
    'emoji_u_1_f_436',
    'emoji_u_1_f_437',
    'emoji_u_1_f_438',
    'emoji_u_1_f_439',
    'emoji_u_1_f_43_a',
    'emoji_u_1_f_43_b',
    'emoji_u_1_f_43_c',
    'emoji_u_1_f_43_d',
    'emoji_u_1_f_43_e',
    'emoji_u_1_f_43_f',
    'emoji_u_1_f_440',
    'emoji_u_1_f_441_200_d_1_f_5_e_8',
    'emoji_u_1_f_441',
    'emoji_u_1_f_442_1_f_3_fb',
    'emoji_u_1_f_442_1_f_3_fc',
    'emoji_u_1_f_442_1_f_3_fd',
    'emoji_u_1_f_442_1_f_3_fe',
    'emoji_u_1_f_442_1_f_3_ff',
    'emoji_u_1_f_442',
    'emoji_u_1_f_443_1_f_3_fb',
    'emoji_u_1_f_443_1_f_3_fc',
    'emoji_u_1_f_443_1_f_3_fd',
    'emoji_u_1_f_443_1_f_3_fe',
    'emoji_u_1_f_443_1_f_3_ff',
    'emoji_u_1_f_443',
    'emoji_u_1_f_444',
    'emoji_u_1_f_445',
    'emoji_u_1_f_446_1_f_3_fb',
    'emoji_u_1_f_446_1_f_3_fc',
    'emoji_u_1_f_446_1_f_3_fd',
    'emoji_u_1_f_446_1_f_3_fe',
    'emoji_u_1_f_446_1_f_3_ff',
    'emoji_u_1_f_446',
    'emoji_u_1_f_447_1_f_3_fb',
    'emoji_u_1_f_447_1_f_3_fc',
    'emoji_u_1_f_447_1_f_3_fd',
    'emoji_u_1_f_447_1_f_3_fe',
    'emoji_u_1_f_447_1_f_3_ff',
    'emoji_u_1_f_447',
    'emoji_u_1_f_448_1_f_3_fb',
    'emoji_u_1_f_448_1_f_3_fc',
    'emoji_u_1_f_448_1_f_3_fd',
    'emoji_u_1_f_448_1_f_3_fe',
    'emoji_u_1_f_448_1_f_3_ff',
    'emoji_u_1_f_448',
    'emoji_u_1_f_449_1_f_3_fb',
    'emoji_u_1_f_449_1_f_3_fc',
    'emoji_u_1_f_449_1_f_3_fd',
    'emoji_u_1_f_449_1_f_3_fe',
    'emoji_u_1_f_449_1_f_3_ff',
    'emoji_u_1_f_449',
    'emoji_u_1_f_44_a_1_f_3_fb',
    'emoji_u_1_f_44_a_1_f_3_fc',
    'emoji_u_1_f_44_a_1_f_3_fd',
    'emoji_u_1_f_44_a_1_f_3_fe',
    'emoji_u_1_f_44_a_1_f_3_ff',
    'emoji_u_1_f_44_a',
    'emoji_u_1_f_44_b_1_f_3_fb',
    'emoji_u_1_f_44_b_1_f_3_fc',
    'emoji_u_1_f_44_b_1_f_3_fd',
    'emoji_u_1_f_44_b_1_f_3_fe',
    'emoji_u_1_f_44_b_1_f_3_ff',
    'emoji_u_1_f_44_b',
    'emoji_u_1_f_44_c_1_f_3_fb',
    'emoji_u_1_f_44_c_1_f_3_fc',
    'emoji_u_1_f_44_c_1_f_3_fd',
    'emoji_u_1_f_44_c_1_f_3_fe',
    'emoji_u_1_f_44_c_1_f_3_ff',
    'emoji_u_1_f_44_c',
    'emoji_u_1_f_44_d_1_f_3_fb',
    'emoji_u_1_f_44_d_1_f_3_fc',
    'emoji_u_1_f_44_d_1_f_3_fd',
    'emoji_u_1_f_44_d_1_f_3_fe',
    'emoji_u_1_f_44_d_1_f_3_ff',
    'emoji_u_1_f_44_d',
    'emoji_u_1_f_44_e_1_f_3_fb',
    'emoji_u_1_f_44_e_1_f_3_fc',
    'emoji_u_1_f_44_e_1_f_3_fd',
    'emoji_u_1_f_44_e_1_f_3_fe',
    'emoji_u_1_f_44_e_1_f_3_ff',
    'emoji_u_1_f_44_e',
    'emoji_u_1_f_44_f_1_f_3_fb',
    'emoji_u_1_f_44_f_1_f_3_fc',
    'emoji_u_1_f_44_f_1_f_3_fd',
    'emoji_u_1_f_44_f_1_f_3_fe',
    'emoji_u_1_f_44_f_1_f_3_ff',
    'emoji_u_1_f_44_f',
    'emoji_u_1_f_450_1_f_3_fb',
    'emoji_u_1_f_450_1_f_3_fc',
    'emoji_u_1_f_450_1_f_3_fd',
    'emoji_u_1_f_450_1_f_3_fe',
    'emoji_u_1_f_450_1_f_3_ff',
    'emoji_u_1_f_450',
    'emoji_u_1_f_451',
    'emoji_u_1_f_452',
    'emoji_u_1_f_453',
    'emoji_u_1_f_454',
    'emoji_u_1_f_455',
    'emoji_u_1_f_456',
    'emoji_u_1_f_457',
    'emoji_u_1_f_458',
    'emoji_u_1_f_459',
    'emoji_u_1_f_45_a',
    'emoji_u_1_f_45_b',
    'emoji_u_1_f_45_c',
    'emoji_u_1_f_45_d',
    'emoji_u_1_f_45_e',
    'emoji_u_1_f_45_f',
    'emoji_u_1_f_460',
    'emoji_u_1_f_461',
    'emoji_u_1_f_462',
    'emoji_u_1_f_463',
    'emoji_u_1_f_464',
    'emoji_u_1_f_465',
    'emoji_u_1_f_466_1_f_3_fb',
    'emoji_u_1_f_466_1_f_3_fc',
    'emoji_u_1_f_466_1_f_3_fd',
    'emoji_u_1_f_466_1_f_3_fe',
    'emoji_u_1_f_466_1_f_3_ff',
    'emoji_u_1_f_466',
    'emoji_u_1_f_467_1_f_3_fb',
    'emoji_u_1_f_467_1_f_3_fc',
    'emoji_u_1_f_467_1_f_3_fd',
    'emoji_u_1_f_467_1_f_3_fe',
    'emoji_u_1_f_467_1_f_3_ff',
    'emoji_u_1_f_467',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_33_e',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_373',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_393',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_3_a_4',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_3_a_8',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_3_eb',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_3_ed',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_4_bb',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_4_bc',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_527',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_52_c',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_680',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_692',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fc',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fd',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fe',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_468_1_f_3_ff',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_9_af',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_9_b_0',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_9_b_1',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_9_b_2',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_9_b_3',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_9_bc',
    'emoji_u_1_f_468_1_f_3_fb_200_d_1_f_9_bd',
    'emoji_u_1_f_468_1_f_3_fb_200_d_2695',
    'emoji_u_1_f_468_1_f_3_fb_200_d_2696',
    'emoji_u_1_f_468_1_f_3_fb_200_d_2708',
    'emoji_u_1_f_468_1_f_3_fb',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_33_e',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_373',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_393',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_3_a_4',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_3_a_8',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_3_eb',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_3_ed',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_4_bb',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_4_bc',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_527',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_52_c',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_680',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_692',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fb',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fd',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fe',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_468_1_f_3_ff',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_9_af',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_9_b_0',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_9_b_1',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_9_b_2',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_9_b_3',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_9_bc',
    'emoji_u_1_f_468_1_f_3_fc_200_d_1_f_9_bd',
    'emoji_u_1_f_468_1_f_3_fc_200_d_2695',
    'emoji_u_1_f_468_1_f_3_fc_200_d_2696',
    'emoji_u_1_f_468_1_f_3_fc_200_d_2708',
    'emoji_u_1_f_468_1_f_3_fc',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_33_e',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_373',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_393',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_3_a_4',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_3_a_8',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_3_eb',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_3_ed',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_4_bb',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_4_bc',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_527',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_52_c',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_680',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_692',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fb',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fc',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fe',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_468_1_f_3_ff',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_9_af',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_9_b_0',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_9_b_1',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_9_b_2',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_9_b_3',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_9_bc',
    'emoji_u_1_f_468_1_f_3_fd_200_d_1_f_9_bd',
    'emoji_u_1_f_468_1_f_3_fd_200_d_2695',
    'emoji_u_1_f_468_1_f_3_fd_200_d_2696',
    'emoji_u_1_f_468_1_f_3_fd_200_d_2708',
    'emoji_u_1_f_468_1_f_3_fd',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_33_e',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_373',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_393',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_3_a_4',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_3_a_8',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_3_eb',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_3_ed',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_4_bb',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_4_bc',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_527',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_52_c',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_680',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_692',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fb',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fc',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fd',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_468_1_f_3_ff',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_9_af',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_9_b_0',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_9_b_1',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_9_b_2',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_9_b_3',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_9_bc',
    'emoji_u_1_f_468_1_f_3_fe_200_d_1_f_9_bd',
    'emoji_u_1_f_468_1_f_3_fe_200_d_2695',
    'emoji_u_1_f_468_1_f_3_fe_200_d_2696',
    'emoji_u_1_f_468_1_f_3_fe_200_d_2708',
    'emoji_u_1_f_468_1_f_3_fe',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_33_e',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_373',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_393',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_3_a_4',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_3_a_8',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_3_eb',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_3_ed',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_4_bb',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_4_bc',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_527',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_52_c',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_680',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_692',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fb',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fc',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fd',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fe',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_9_af',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_9_b_0',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_9_b_1',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_9_b_2',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_9_b_3',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_9_bc',
    'emoji_u_1_f_468_1_f_3_ff_200_d_1_f_9_bd',
    'emoji_u_1_f_468_1_f_3_ff_200_d_2695',
    'emoji_u_1_f_468_1_f_3_ff_200_d_2696',
    'emoji_u_1_f_468_1_f_3_ff_200_d_2708',
    'emoji_u_1_f_468_1_f_3_ff',
    'emoji_u_1_f_468_200_d_1_f_33_e',
    'emoji_u_1_f_468_200_d_1_f_373',
    'emoji_u_1_f_468_200_d_1_f_393',
    'emoji_u_1_f_468_200_d_1_f_3_a_4',
    'emoji_u_1_f_468_200_d_1_f_3_a_8',
    'emoji_u_1_f_468_200_d_1_f_3_eb',
    'emoji_u_1_f_468_200_d_1_f_3_ed',
    'emoji_u_1_f_468_200_d_1_f_466_200_d_1_f_466',
    'emoji_u_1_f_468_200_d_1_f_466',
    'emoji_u_1_f_468_200_d_1_f_467_200_d_1_f_466',
    'emoji_u_1_f_468_200_d_1_f_467_200_d_1_f_467',
    'emoji_u_1_f_468_200_d_1_f_467',
    'emoji_u_1_f_468_200_d_1_f_468_200_d_1_f_466_200_d_1_f_466',
    'emoji_u_1_f_468_200_d_1_f_468_200_d_1_f_466',
    'emoji_u_1_f_468_200_d_1_f_468_200_d_1_f_467_200_d_1_f_466',
    'emoji_u_1_f_468_200_d_1_f_468_200_d_1_f_467_200_d_1_f_467',
    'emoji_u_1_f_468_200_d_1_f_468_200_d_1_f_467',
    'emoji_u_1_f_468_200_d_1_f_469_200_d_1_f_466_200_d_1_f_466',
    'emoji_u_1_f_468_200_d_1_f_469_200_d_1_f_466',
    'emoji_u_1_f_468_200_d_1_f_469_200_d_1_f_467_200_d_1_f_466',
    'emoji_u_1_f_468_200_d_1_f_469_200_d_1_f_467_200_d_1_f_467',
    'emoji_u_1_f_468_200_d_1_f_469_200_d_1_f_467',
    'emoji_u_1_f_468_200_d_1_f_4_bb',
    'emoji_u_1_f_468_200_d_1_f_4_bc',
    'emoji_u_1_f_468_200_d_1_f_527',
    'emoji_u_1_f_468_200_d_1_f_52_c',
    'emoji_u_1_f_468_200_d_1_f_680',
    'emoji_u_1_f_468_200_d_1_f_692',
    'emoji_u_1_f_468_200_d_1_f_9_af',
    'emoji_u_1_f_468_200_d_1_f_9_b_0',
    'emoji_u_1_f_468_200_d_1_f_9_b_1',
    'emoji_u_1_f_468_200_d_1_f_9_b_2',
    'emoji_u_1_f_468_200_d_1_f_9_b_3',
    'emoji_u_1_f_468_200_d_1_f_9_bc',
    'emoji_u_1_f_468_200_d_1_f_9_bd',
    'emoji_u_1_f_468_200_d_2695',
    'emoji_u_1_f_468_200_d_2696',
    'emoji_u_1_f_468_200_d_2708',
    'emoji_u_1_f_468_200_d_2764_200_d_1_f_468',
    'emoji_u_1_f_468_200_d_2764_200_d_1_f_48_b_200_d_1_f_468',
    'emoji_u_1_f_468',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_33_e',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_373',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_393',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_3_a_4',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_3_a_8',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_3_eb',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_3_ed',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_4_bb',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_4_bc',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_527',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_52_c',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_680',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_692',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fc',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fd',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fe',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_468_1_f_3_ff',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fc',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fd',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fe',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_469_1_f_3_ff',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_9_af',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_9_b_0',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_9_b_1',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_9_b_2',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_9_b_3',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_9_bc',
    'emoji_u_1_f_469_1_f_3_fb_200_d_1_f_9_bd',
    'emoji_u_1_f_469_1_f_3_fb_200_d_2695',
    'emoji_u_1_f_469_1_f_3_fb_200_d_2696',
    'emoji_u_1_f_469_1_f_3_fb_200_d_2708',
    'emoji_u_1_f_469_1_f_3_fb',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_33_e',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_373',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_393',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_3_a_4',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_3_a_8',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_3_eb',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_3_ed',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_4_bb',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_4_bc',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_527',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_52_c',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_680',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_692',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fb',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fd',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fe',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_468_1_f_3_ff',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fb',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fd',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fe',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_469_1_f_3_ff',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_9_af',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_9_b_0',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_9_b_1',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_9_b_2',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_9_b_3',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_9_bc',
    'emoji_u_1_f_469_1_f_3_fc_200_d_1_f_9_bd',
    'emoji_u_1_f_469_1_f_3_fc_200_d_2695',
    'emoji_u_1_f_469_1_f_3_fc_200_d_2696',
    'emoji_u_1_f_469_1_f_3_fc_200_d_2708',
    'emoji_u_1_f_469_1_f_3_fc',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_33_e',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_373',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_393',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_3_a_4',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_3_a_8',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_3_eb',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_3_ed',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_4_bb',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_4_bc',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_527',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_52_c',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_680',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_692',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fb',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fc',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fe',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_468_1_f_3_ff',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fb',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fc',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fe',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_469_1_f_3_ff',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_9_af',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_9_b_0',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_9_b_1',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_9_b_2',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_9_b_3',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_9_bc',
    'emoji_u_1_f_469_1_f_3_fd_200_d_1_f_9_bd',
    'emoji_u_1_f_469_1_f_3_fd_200_d_2695',
    'emoji_u_1_f_469_1_f_3_fd_200_d_2696',
    'emoji_u_1_f_469_1_f_3_fd_200_d_2708',
    'emoji_u_1_f_469_1_f_3_fd',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_33_e',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_373',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_393',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_3_a_4',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_3_a_8',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_3_eb',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_3_ed',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_4_bb',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_4_bc',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_527',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_52_c',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_680',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_692',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fb',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fc',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fd',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_468_1_f_3_ff',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fb',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fc',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fd',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_469_1_f_3_ff',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_9_af',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_9_b_0',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_9_b_1',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_9_b_2',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_9_b_3',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_9_bc',
    'emoji_u_1_f_469_1_f_3_fe_200_d_1_f_9_bd',
    'emoji_u_1_f_469_1_f_3_fe_200_d_2695',
    'emoji_u_1_f_469_1_f_3_fe_200_d_2696',
    'emoji_u_1_f_469_1_f_3_fe_200_d_2708',
    'emoji_u_1_f_469_1_f_3_fe',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_33_e',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_373',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_393',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_3_a_4',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_3_a_8',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_3_eb',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_3_ed',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_4_bb',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_4_bc',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_527',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_52_c',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_680',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_692',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fb',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fc',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fd',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_468_1_f_3_fe',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fb',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fc',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fd',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_469_1_f_3_fe',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_9_af',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_9_b_0',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_9_b_1',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_9_b_2',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_9_b_3',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_9_bc',
    'emoji_u_1_f_469_1_f_3_ff_200_d_1_f_9_bd',
    'emoji_u_1_f_469_1_f_3_ff_200_d_2695',
    'emoji_u_1_f_469_1_f_3_ff_200_d_2696',
    'emoji_u_1_f_469_1_f_3_ff_200_d_2708',
    'emoji_u_1_f_469_1_f_3_ff',
    'emoji_u_1_f_469_200_d_1_f_33_e',
    'emoji_u_1_f_469_200_d_1_f_373',
    'emoji_u_1_f_469_200_d_1_f_393',
    'emoji_u_1_f_469_200_d_1_f_3_a_4',
    'emoji_u_1_f_469_200_d_1_f_3_a_8',
    'emoji_u_1_f_469_200_d_1_f_3_eb',
    'emoji_u_1_f_469_200_d_1_f_3_ed',
    'emoji_u_1_f_469_200_d_1_f_466_200_d_1_f_466',
    'emoji_u_1_f_469_200_d_1_f_466',
    'emoji_u_1_f_469_200_d_1_f_467_200_d_1_f_466',
    'emoji_u_1_f_469_200_d_1_f_467_200_d_1_f_467',
    'emoji_u_1_f_469_200_d_1_f_467',
    'emoji_u_1_f_469_200_d_1_f_469_200_d_1_f_466_200_d_1_f_466',
    'emoji_u_1_f_469_200_d_1_f_469_200_d_1_f_466',
    'emoji_u_1_f_469_200_d_1_f_469_200_d_1_f_467_200_d_1_f_466',
    'emoji_u_1_f_469_200_d_1_f_469_200_d_1_f_467_200_d_1_f_467',
    'emoji_u_1_f_469_200_d_1_f_469_200_d_1_f_467',
    'emoji_u_1_f_469_200_d_1_f_4_bb',
    'emoji_u_1_f_469_200_d_1_f_4_bc',
    'emoji_u_1_f_469_200_d_1_f_527',
    'emoji_u_1_f_469_200_d_1_f_52_c',
    'emoji_u_1_f_469_200_d_1_f_680',
    'emoji_u_1_f_469_200_d_1_f_692',
    'emoji_u_1_f_469_200_d_1_f_9_af',
    'emoji_u_1_f_469_200_d_1_f_9_b_0',
    'emoji_u_1_f_469_200_d_1_f_9_b_1',
    'emoji_u_1_f_469_200_d_1_f_9_b_2',
    'emoji_u_1_f_469_200_d_1_f_9_b_3',
    'emoji_u_1_f_469_200_d_1_f_9_bc',
    'emoji_u_1_f_469_200_d_1_f_9_bd',
    'emoji_u_1_f_469_200_d_2695',
    'emoji_u_1_f_469_200_d_2696',
    'emoji_u_1_f_469_200_d_2708',
    'emoji_u_1_f_469_200_d_2764_200_d_1_f_468',
    'emoji_u_1_f_469_200_d_2764_200_d_1_f_469',
    'emoji_u_1_f_469_200_d_2764_200_d_1_f_48_b_200_d_1_f_468',
    'emoji_u_1_f_469_200_d_2764_200_d_1_f_48_b_200_d_1_f_469',
    'emoji_u_1_f_469',
    'emoji_u_1_f_46_a',
    'emoji_u_1_f_46_b_1_f_3_fb',
    'emoji_u_1_f_46_b_1_f_3_fc',
    'emoji_u_1_f_46_b_1_f_3_fd',
    'emoji_u_1_f_46_b_1_f_3_fe',
    'emoji_u_1_f_46_b_1_f_3_ff',
    'emoji_u_1_f_46_b',
    'emoji_u_1_f_46_c_1_f_3_fb',
    'emoji_u_1_f_46_c_1_f_3_fc',
    'emoji_u_1_f_46_c_1_f_3_fd',
    'emoji_u_1_f_46_c_1_f_3_fe',
    'emoji_u_1_f_46_c_1_f_3_ff',
    'emoji_u_1_f_46_c',
    'emoji_u_1_f_46_d_1_f_3_fb',
    'emoji_u_1_f_46_d_1_f_3_fc',
    'emoji_u_1_f_46_d_1_f_3_fd',
    'emoji_u_1_f_46_d_1_f_3_fe',
    'emoji_u_1_f_46_d_1_f_3_ff',
    'emoji_u_1_f_46_d',
    'emoji_u_1_f_46_e_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_46_e_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_46_e_1_f_3_fb',
    'emoji_u_1_f_46_e_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_46_e_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_46_e_1_f_3_fc',
    'emoji_u_1_f_46_e_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_46_e_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_46_e_1_f_3_fd',
    'emoji_u_1_f_46_e_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_46_e_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_46_e_1_f_3_fe',
    'emoji_u_1_f_46_e_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_46_e_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_46_e_1_f_3_ff',
    'emoji_u_1_f_46_e_200_d_2640',
    'emoji_u_1_f_46_e_200_d_2642',
    'emoji_u_1_f_46_e',
    'emoji_u_1_f_46_f_200_d_2640',
    'emoji_u_1_f_46_f_200_d_2642',
    'emoji_u_1_f_46_f',
    'emoji_u_1_f_470_1_f_3_fb',
    'emoji_u_1_f_470_1_f_3_fc',
    'emoji_u_1_f_470_1_f_3_fd',
    'emoji_u_1_f_470_1_f_3_fe',
    'emoji_u_1_f_470_1_f_3_ff',
    'emoji_u_1_f_470',
    'emoji_u_1_f_471_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_471_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_471_1_f_3_fb',
    'emoji_u_1_f_471_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_471_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_471_1_f_3_fc',
    'emoji_u_1_f_471_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_471_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_471_1_f_3_fd',
    'emoji_u_1_f_471_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_471_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_471_1_f_3_fe',
    'emoji_u_1_f_471_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_471_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_471_1_f_3_ff',
    'emoji_u_1_f_471_200_d_2640',
    'emoji_u_1_f_471_200_d_2642',
    'emoji_u_1_f_471',
    'emoji_u_1_f_472_1_f_3_fb',
    'emoji_u_1_f_472_1_f_3_fc',
    'emoji_u_1_f_472_1_f_3_fd',
    'emoji_u_1_f_472_1_f_3_fe',
    'emoji_u_1_f_472_1_f_3_ff',
    'emoji_u_1_f_472',
    'emoji_u_1_f_473_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_473_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_473_1_f_3_fb',
    'emoji_u_1_f_473_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_473_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_473_1_f_3_fc',
    'emoji_u_1_f_473_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_473_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_473_1_f_3_fd',
    'emoji_u_1_f_473_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_473_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_473_1_f_3_fe',
    'emoji_u_1_f_473_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_473_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_473_1_f_3_ff',
    'emoji_u_1_f_473_200_d_2640',
    'emoji_u_1_f_473_200_d_2642',
    'emoji_u_1_f_473',
    'emoji_u_1_f_474_1_f_3_fb',
    'emoji_u_1_f_474_1_f_3_fc',
    'emoji_u_1_f_474_1_f_3_fd',
    'emoji_u_1_f_474_1_f_3_fe',
    'emoji_u_1_f_474_1_f_3_ff',
    'emoji_u_1_f_474',
    'emoji_u_1_f_475_1_f_3_fb',
    'emoji_u_1_f_475_1_f_3_fc',
    'emoji_u_1_f_475_1_f_3_fd',
    'emoji_u_1_f_475_1_f_3_fe',
    'emoji_u_1_f_475_1_f_3_ff',
    'emoji_u_1_f_475',
    'emoji_u_1_f_476_1_f_3_fb',
    'emoji_u_1_f_476_1_f_3_fc',
    'emoji_u_1_f_476_1_f_3_fd',
    'emoji_u_1_f_476_1_f_3_fe',
    'emoji_u_1_f_476_1_f_3_ff',
    'emoji_u_1_f_476',
    'emoji_u_1_f_477_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_477_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_477_1_f_3_fb',
    'emoji_u_1_f_477_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_477_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_477_1_f_3_fc',
    'emoji_u_1_f_477_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_477_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_477_1_f_3_fd',
    'emoji_u_1_f_477_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_477_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_477_1_f_3_fe',
    'emoji_u_1_f_477_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_477_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_477_1_f_3_ff',
    'emoji_u_1_f_477_200_d_2640',
    'emoji_u_1_f_477_200_d_2642',
    'emoji_u_1_f_477',
    'emoji_u_1_f_478_1_f_3_fb',
    'emoji_u_1_f_478_1_f_3_fc',
    'emoji_u_1_f_478_1_f_3_fd',
    'emoji_u_1_f_478_1_f_3_fe',
    'emoji_u_1_f_478_1_f_3_ff',
    'emoji_u_1_f_478',
    'emoji_u_1_f_479',
    'emoji_u_1_f_47_a',
    'emoji_u_1_f_47_b',
    'emoji_u_1_f_47_c_1_f_3_fb',
    'emoji_u_1_f_47_c_1_f_3_fc',
    'emoji_u_1_f_47_c_1_f_3_fd',
    'emoji_u_1_f_47_c_1_f_3_fe',
    'emoji_u_1_f_47_c_1_f_3_ff',
    'emoji_u_1_f_47_c',
    'emoji_u_1_f_47_d',
    'emoji_u_1_f_47_e',
    'emoji_u_1_f_47_f',
    'emoji_u_1_f_480',
    'emoji_u_1_f_481_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_481_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_481_1_f_3_fb',
    'emoji_u_1_f_481_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_481_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_481_1_f_3_fc',
    'emoji_u_1_f_481_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_481_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_481_1_f_3_fd',
    'emoji_u_1_f_481_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_481_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_481_1_f_3_fe',
    'emoji_u_1_f_481_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_481_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_481_1_f_3_ff',
    'emoji_u_1_f_481_200_d_2640',
    'emoji_u_1_f_481_200_d_2642',
    'emoji_u_1_f_481',
    'emoji_u_1_f_482_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_482_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_482_1_f_3_fb',
    'emoji_u_1_f_482_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_482_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_482_1_f_3_fc',
    'emoji_u_1_f_482_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_482_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_482_1_f_3_fd',
    'emoji_u_1_f_482_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_482_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_482_1_f_3_fe',
    'emoji_u_1_f_482_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_482_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_482_1_f_3_ff',
    'emoji_u_1_f_482_200_d_2640',
    'emoji_u_1_f_482_200_d_2642',
    'emoji_u_1_f_482',
    'emoji_u_1_f_483_1_f_3_fb',
    'emoji_u_1_f_483_1_f_3_fc',
    'emoji_u_1_f_483_1_f_3_fd',
    'emoji_u_1_f_483_1_f_3_fe',
    'emoji_u_1_f_483_1_f_3_ff',
    'emoji_u_1_f_483',
    'emoji_u_1_f_484',
    'emoji_u_1_f_485_1_f_3_fb',
    'emoji_u_1_f_485_1_f_3_fc',
    'emoji_u_1_f_485_1_f_3_fd',
    'emoji_u_1_f_485_1_f_3_fe',
    'emoji_u_1_f_485_1_f_3_ff',
    'emoji_u_1_f_485',
    'emoji_u_1_f_486_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_486_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_486_1_f_3_fb',
    'emoji_u_1_f_486_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_486_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_486_1_f_3_fc',
    'emoji_u_1_f_486_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_486_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_486_1_f_3_fd',
    'emoji_u_1_f_486_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_486_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_486_1_f_3_fe',
    'emoji_u_1_f_486_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_486_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_486_1_f_3_ff',
    'emoji_u_1_f_486_200_d_2640',
    'emoji_u_1_f_486_200_d_2642',
    'emoji_u_1_f_486',
    'emoji_u_1_f_487_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_487_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_487_1_f_3_fb',
    'emoji_u_1_f_487_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_487_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_487_1_f_3_fc',
    'emoji_u_1_f_487_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_487_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_487_1_f_3_fd',
    'emoji_u_1_f_487_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_487_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_487_1_f_3_fe',
    'emoji_u_1_f_487_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_487_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_487_1_f_3_ff',
    'emoji_u_1_f_487_200_d_2640',
    'emoji_u_1_f_487_200_d_2642',
    'emoji_u_1_f_487',
    'emoji_u_1_f_488',
    'emoji_u_1_f_489',
    'emoji_u_1_f_48_a',
    'emoji_u_1_f_48_b',
    'emoji_u_1_f_48_c',
    'emoji_u_1_f_48_d',
    'emoji_u_1_f_48_e',
    'emoji_u_1_f_48_f',
    'emoji_u_1_f_490',
    'emoji_u_1_f_491',
    'emoji_u_1_f_492',
    'emoji_u_1_f_493',
    'emoji_u_1_f_494',
    'emoji_u_1_f_495',
    'emoji_u_1_f_496',
    'emoji_u_1_f_497',
    'emoji_u_1_f_498',
    'emoji_u_1_f_499',
    'emoji_u_1_f_49_a',
    'emoji_u_1_f_49_b',
    'emoji_u_1_f_49_c',
    'emoji_u_1_f_49_d',
    'emoji_u_1_f_49_e',
    'emoji_u_1_f_49_f',
    'emoji_u_1_f_4_a_0',
    'emoji_u_1_f_4_a_1',
    'emoji_u_1_f_4_a_2',
    'emoji_u_1_f_4_a_3',
    'emoji_u_1_f_4_a_4',
    'emoji_u_1_f_4_a_5',
    'emoji_u_1_f_4_a_6',
    'emoji_u_1_f_4_a_7',
    'emoji_u_1_f_4_a_8',
    'emoji_u_1_f_4_a_9',
    'emoji_u_1_f_4_aa_1_f_3_fb',
    'emoji_u_1_f_4_aa_1_f_3_fc',
    'emoji_u_1_f_4_aa_1_f_3_fd',
    'emoji_u_1_f_4_aa_1_f_3_fe',
    'emoji_u_1_f_4_aa_1_f_3_ff',
    'emoji_u_1_f_4_aa',
    'emoji_u_1_f_4_ab',
    'emoji_u_1_f_4_ac',
    'emoji_u_1_f_4_ad',
    'emoji_u_1_f_4_ae',
    'emoji_u_1_f_4_af',
    'emoji_u_1_f_4_b_0',
    'emoji_u_1_f_4_b_1',
    'emoji_u_1_f_4_b_2',
    'emoji_u_1_f_4_b_3',
    'emoji_u_1_f_4_b_4',
    'emoji_u_1_f_4_b_5',
    'emoji_u_1_f_4_b_6',
    'emoji_u_1_f_4_b_7',
    'emoji_u_1_f_4_b_8',
    'emoji_u_1_f_4_b_9',
    'emoji_u_1_f_4_ba',
    'emoji_u_1_f_4_bb',
    'emoji_u_1_f_4_bc',
    'emoji_u_1_f_4_bd',
    'emoji_u_1_f_4_be',
    'emoji_u_1_f_4_bf',
    'emoji_u_1_f_4_c_0',
    'emoji_u_1_f_4_c_1',
    'emoji_u_1_f_4_c_2',
    'emoji_u_1_f_4_c_3',
    'emoji_u_1_f_4_c_4',
    'emoji_u_1_f_4_c_5',
    'emoji_u_1_f_4_c_6',
    'emoji_u_1_f_4_c_7',
    'emoji_u_1_f_4_c_8',
    'emoji_u_1_f_4_c_9',
    'emoji_u_1_f_4_ca',
    'emoji_u_1_f_4_cb',
    'emoji_u_1_f_4_cc',
    'emoji_u_1_f_4_cd',
    'emoji_u_1_f_4_ce',
    'emoji_u_1_f_4_cf',
    'emoji_u_1_f_4_d_0',
    'emoji_u_1_f_4_d_1',
    'emoji_u_1_f_4_d_2',
    'emoji_u_1_f_4_d_3',
    'emoji_u_1_f_4_d_4',
    'emoji_u_1_f_4_d_5',
    'emoji_u_1_f_4_d_6',
    'emoji_u_1_f_4_d_7',
    'emoji_u_1_f_4_d_8',
    'emoji_u_1_f_4_d_9',
    'emoji_u_1_f_4_da',
    'emoji_u_1_f_4_db',
    'emoji_u_1_f_4_dc',
    'emoji_u_1_f_4_dd',
    'emoji_u_1_f_4_de',
    'emoji_u_1_f_4_df',
    'emoji_u_1_f_4_e_0',
    'emoji_u_1_f_4_e_1',
    'emoji_u_1_f_4_e_2',
    'emoji_u_1_f_4_e_3',
    'emoji_u_1_f_4_e_4',
    'emoji_u_1_f_4_e_5',
    'emoji_u_1_f_4_e_6',
    'emoji_u_1_f_4_e_7',
    'emoji_u_1_f_4_e_8',
    'emoji_u_1_f_4_e_9',
    'emoji_u_1_f_4_ea',
    'emoji_u_1_f_4_eb',
    'emoji_u_1_f_4_ec',
    'emoji_u_1_f_4_ed',
    'emoji_u_1_f_4_ee',
    'emoji_u_1_f_4_ef',
    'emoji_u_1_f_4_f_0',
    'emoji_u_1_f_4_f_1',
    'emoji_u_1_f_4_f_2',
    'emoji_u_1_f_4_f_3',
    'emoji_u_1_f_4_f_4',
    'emoji_u_1_f_4_f_5',
    'emoji_u_1_f_4_f_6',
    'emoji_u_1_f_4_f_7',
    'emoji_u_1_f_4_f_8',
    'emoji_u_1_f_4_f_9',
    'emoji_u_1_f_4_fa',
    'emoji_u_1_f_4_fb',
    'emoji_u_1_f_4_fc',
    'emoji_u_1_f_4_fd',
    'emoji_u_1_f_4_ff',
    'emoji_u_1_f_500',
    'emoji_u_1_f_501',
    'emoji_u_1_f_502',
    'emoji_u_1_f_503',
    'emoji_u_1_f_504',
    'emoji_u_1_f_505',
    'emoji_u_1_f_506',
    'emoji_u_1_f_507',
    'emoji_u_1_f_508',
    'emoji_u_1_f_509',
    'emoji_u_1_f_50_a',
    'emoji_u_1_f_50_b',
    'emoji_u_1_f_50_c',
    'emoji_u_1_f_50_d',
    'emoji_u_1_f_50_e',
    'emoji_u_1_f_50_f',
    'emoji_u_1_f_510',
    'emoji_u_1_f_511',
    'emoji_u_1_f_512',
    'emoji_u_1_f_513',
    'emoji_u_1_f_514',
    'emoji_u_1_f_515',
    'emoji_u_1_f_516',
    'emoji_u_1_f_517',
    'emoji_u_1_f_518',
    'emoji_u_1_f_519',
    'emoji_u_1_f_51_a',
    'emoji_u_1_f_51_b',
    'emoji_u_1_f_51_c',
    'emoji_u_1_f_51_d',
    'emoji_u_1_f_51_e',
    'emoji_u_1_f_51_f',
    'emoji_u_1_f_520',
    'emoji_u_1_f_521',
    'emoji_u_1_f_522',
    'emoji_u_1_f_523',
    'emoji_u_1_f_524',
    'emoji_u_1_f_525',
    'emoji_u_1_f_526',
    'emoji_u_1_f_527',
    'emoji_u_1_f_528',
    'emoji_u_1_f_529',
    'emoji_u_1_f_52_a',
    'emoji_u_1_f_52_b',
    'emoji_u_1_f_52_c',
    'emoji_u_1_f_52_d',
    'emoji_u_1_f_52_e',
    'emoji_u_1_f_52_f',
    'emoji_u_1_f_530',
    'emoji_u_1_f_531',
    'emoji_u_1_f_532',
    'emoji_u_1_f_533',
    'emoji_u_1_f_534',
    'emoji_u_1_f_535',
    'emoji_u_1_f_536',
    'emoji_u_1_f_537',
    'emoji_u_1_f_538',
    'emoji_u_1_f_539',
    'emoji_u_1_f_53_a',
    'emoji_u_1_f_53_b',
    'emoji_u_1_f_53_c',
    'emoji_u_1_f_53_d',
    'emoji_u_1_f_549',
    'emoji_u_1_f_54_a',
    'emoji_u_1_f_54_b',
    'emoji_u_1_f_54_c',
    'emoji_u_1_f_54_d',
    'emoji_u_1_f_54_e',
    'emoji_u_1_f_550',
    'emoji_u_1_f_551',
    'emoji_u_1_f_552',
    'emoji_u_1_f_553',
    'emoji_u_1_f_554',
    'emoji_u_1_f_555',
    'emoji_u_1_f_556',
    'emoji_u_1_f_557',
    'emoji_u_1_f_558',
    'emoji_u_1_f_559',
    'emoji_u_1_f_55_a',
    'emoji_u_1_f_55_b',
    'emoji_u_1_f_55_c',
    'emoji_u_1_f_55_d',
    'emoji_u_1_f_55_e',
    'emoji_u_1_f_55_f',
    'emoji_u_1_f_560',
    'emoji_u_1_f_561',
    'emoji_u_1_f_562',
    'emoji_u_1_f_563',
    'emoji_u_1_f_564',
    'emoji_u_1_f_565',
    'emoji_u_1_f_566',
    'emoji_u_1_f_567',
    'emoji_u_1_f_56_f',
    'emoji_u_1_f_570',
    'emoji_u_1_f_573',
    'emoji_u_1_f_574_1_f_3_fb',
    'emoji_u_1_f_574_1_f_3_fc',
    'emoji_u_1_f_574_1_f_3_fd',
    'emoji_u_1_f_574_1_f_3_fe',
    'emoji_u_1_f_574_1_f_3_ff',
    'emoji_u_1_f_574',
    'emoji_u_1_f_575_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_575_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_575_1_f_3_fb',
    'emoji_u_1_f_575_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_575_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_575_1_f_3_fc',
    'emoji_u_1_f_575_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_575_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_575_1_f_3_fd',
    'emoji_u_1_f_575_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_575_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_575_1_f_3_fe',
    'emoji_u_1_f_575_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_575_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_575_1_f_3_ff',
    'emoji_u_1_f_575_200_d_2640',
    'emoji_u_1_f_575_200_d_2642',
    'emoji_u_1_f_575',
    'emoji_u_1_f_576',
    'emoji_u_1_f_577',
    'emoji_u_1_f_578',
    'emoji_u_1_f_579',
    'emoji_u_1_f_57_a_1_f_3_fb',
    'emoji_u_1_f_57_a_1_f_3_fc',
    'emoji_u_1_f_57_a_1_f_3_fd',
    'emoji_u_1_f_57_a_1_f_3_fe',
    'emoji_u_1_f_57_a_1_f_3_ff',
    'emoji_u_1_f_57_a',
    'emoji_u_1_f_587',
    'emoji_u_1_f_58_a',
    'emoji_u_1_f_58_b',
    'emoji_u_1_f_58_c',
    'emoji_u_1_f_58_d',
    'emoji_u_1_f_590_1_f_3_fb',
    'emoji_u_1_f_590_1_f_3_fc',
    'emoji_u_1_f_590_1_f_3_fd',
    'emoji_u_1_f_590_1_f_3_fe',
    'emoji_u_1_f_590_1_f_3_ff',
    'emoji_u_1_f_590',
    'emoji_u_1_f_595_1_f_3_fb',
    'emoji_u_1_f_595_1_f_3_fc',
    'emoji_u_1_f_595_1_f_3_fd',
    'emoji_u_1_f_595_1_f_3_fe',
    'emoji_u_1_f_595_1_f_3_ff',
    'emoji_u_1_f_595',
    'emoji_u_1_f_596_1_f_3_fb',
    'emoji_u_1_f_596_1_f_3_fc',
    'emoji_u_1_f_596_1_f_3_fd',
    'emoji_u_1_f_596_1_f_3_fe',
    'emoji_u_1_f_596_1_f_3_ff',
    'emoji_u_1_f_596',
    'emoji_u_1_f_5_a_4',
    'emoji_u_1_f_5_a_5',
    'emoji_u_1_f_5_a_8',
    'emoji_u_1_f_5_b_1',
    'emoji_u_1_f_5_b_2',
    'emoji_u_1_f_5_bc',
    'emoji_u_1_f_5_c_2',
    'emoji_u_1_f_5_c_3',
    'emoji_u_1_f_5_c_4',
    'emoji_u_1_f_5_d_1',
    'emoji_u_1_f_5_d_2',
    'emoji_u_1_f_5_d_3',
    'emoji_u_1_f_5_dc',
    'emoji_u_1_f_5_dd',
    'emoji_u_1_f_5_de',
    'emoji_u_1_f_5_e_1',
    'emoji_u_1_f_5_e_3',
    'emoji_u_1_f_5_e_8',
    'emoji_u_1_f_5_ef',
    'emoji_u_1_f_5_f_3',
    'emoji_u_1_f_5_fa',
    'emoji_u_1_f_5_fb',
    'emoji_u_1_f_5_fc',
    'emoji_u_1_f_5_fd',
    'emoji_u_1_f_5_fe',
    'emoji_u_1_f_5_ff',
    'emoji_u_1_f_600',
    'emoji_u_1_f_601',
    'emoji_u_1_f_602',
    'emoji_u_1_f_603',
    'emoji_u_1_f_604',
    'emoji_u_1_f_605',
    'emoji_u_1_f_606',
    'emoji_u_1_f_607',
    'emoji_u_1_f_608',
    'emoji_u_1_f_609',
    'emoji_u_1_f_60_a',
    'emoji_u_1_f_60_b',
    'emoji_u_1_f_60_c',
    'emoji_u_1_f_60_d',
    'emoji_u_1_f_60_e',
    'emoji_u_1_f_60_f',
    'emoji_u_1_f_610',
    'emoji_u_1_f_611',
    'emoji_u_1_f_612',
    'emoji_u_1_f_613',
    'emoji_u_1_f_614',
    'emoji_u_1_f_615',
    'emoji_u_1_f_616',
    'emoji_u_1_f_617',
    'emoji_u_1_f_618',
    'emoji_u_1_f_619',
    'emoji_u_1_f_61_a',
    'emoji_u_1_f_61_b',
    'emoji_u_1_f_61_c',
    'emoji_u_1_f_61_d',
    'emoji_u_1_f_61_e',
    'emoji_u_1_f_61_f',
    'emoji_u_1_f_620',
    'emoji_u_1_f_621',
    'emoji_u_1_f_622',
    'emoji_u_1_f_623',
    'emoji_u_1_f_624',
    'emoji_u_1_f_625',
    'emoji_u_1_f_626',
    'emoji_u_1_f_627',
    'emoji_u_1_f_628',
    'emoji_u_1_f_629',
    'emoji_u_1_f_62_a',
    'emoji_u_1_f_62_b',
    'emoji_u_1_f_62_c',
    'emoji_u_1_f_62_d',
    'emoji_u_1_f_62_e',
    'emoji_u_1_f_62_f',
    'emoji_u_1_f_630',
    'emoji_u_1_f_631',
    'emoji_u_1_f_632',
    'emoji_u_1_f_633',
    'emoji_u_1_f_634',
    'emoji_u_1_f_635',
    'emoji_u_1_f_636',
    'emoji_u_1_f_637',
    'emoji_u_1_f_638',
    'emoji_u_1_f_639',
    'emoji_u_1_f_63_a',
    'emoji_u_1_f_63_b',
    'emoji_u_1_f_63_c',
    'emoji_u_1_f_63_d',
    'emoji_u_1_f_63_e',
    'emoji_u_1_f_63_f',
    'emoji_u_1_f_640',
    'emoji_u_1_f_641',
    'emoji_u_1_f_642',
    'emoji_u_1_f_643',
    'emoji_u_1_f_644',
    'emoji_u_1_f_645_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_645_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_645_1_f_3_fb',
    'emoji_u_1_f_645_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_645_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_645_1_f_3_fc',
    'emoji_u_1_f_645_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_645_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_645_1_f_3_fd',
    'emoji_u_1_f_645_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_645_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_645_1_f_3_fe',
    'emoji_u_1_f_645_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_645_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_645_1_f_3_ff',
    'emoji_u_1_f_645_200_d_2640',
    'emoji_u_1_f_645_200_d_2642',
    'emoji_u_1_f_645',
    'emoji_u_1_f_646_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_646_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_646_1_f_3_fb',
    'emoji_u_1_f_646_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_646_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_646_1_f_3_fc',
    'emoji_u_1_f_646_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_646_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_646_1_f_3_fd',
    'emoji_u_1_f_646_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_646_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_646_1_f_3_fe',
    'emoji_u_1_f_646_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_646_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_646_1_f_3_ff',
    'emoji_u_1_f_646_200_d_2640',
    'emoji_u_1_f_646_200_d_2642',
    'emoji_u_1_f_646',
    'emoji_u_1_f_647_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_647_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_647_1_f_3_fb',
    'emoji_u_1_f_647_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_647_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_647_1_f_3_fc',
    'emoji_u_1_f_647_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_647_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_647_1_f_3_fd',
    'emoji_u_1_f_647_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_647_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_647_1_f_3_fe',
    'emoji_u_1_f_647_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_647_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_647_1_f_3_ff',
    'emoji_u_1_f_647_200_d_2640',
    'emoji_u_1_f_647_200_d_2642',
    'emoji_u_1_f_647',
    'emoji_u_1_f_648',
    'emoji_u_1_f_649',
    'emoji_u_1_f_64_a',
    'emoji_u_1_f_64_b_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_64_b_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_64_b_1_f_3_fb',
    'emoji_u_1_f_64_b_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_64_b_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_64_b_1_f_3_fc',
    'emoji_u_1_f_64_b_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_64_b_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_64_b_1_f_3_fd',
    'emoji_u_1_f_64_b_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_64_b_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_64_b_1_f_3_fe',
    'emoji_u_1_f_64_b_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_64_b_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_64_b_1_f_3_ff',
    'emoji_u_1_f_64_b_200_d_2640',
    'emoji_u_1_f_64_b_200_d_2642',
    'emoji_u_1_f_64_b',
    'emoji_u_1_f_64_c_1_f_3_fb',
    'emoji_u_1_f_64_c_1_f_3_fc',
    'emoji_u_1_f_64_c_1_f_3_fd',
    'emoji_u_1_f_64_c_1_f_3_fe',
    'emoji_u_1_f_64_c_1_f_3_ff',
    'emoji_u_1_f_64_c',
    'emoji_u_1_f_64_d_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_64_d_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_64_d_1_f_3_fb',
    'emoji_u_1_f_64_d_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_64_d_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_64_d_1_f_3_fc',
    'emoji_u_1_f_64_d_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_64_d_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_64_d_1_f_3_fd',
    'emoji_u_1_f_64_d_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_64_d_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_64_d_1_f_3_fe',
    'emoji_u_1_f_64_d_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_64_d_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_64_d_1_f_3_ff',
    'emoji_u_1_f_64_d_200_d_2640',
    'emoji_u_1_f_64_d_200_d_2642',
    'emoji_u_1_f_64_d',
    'emoji_u_1_f_64_e_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_64_e_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_64_e_1_f_3_fb',
    'emoji_u_1_f_64_e_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_64_e_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_64_e_1_f_3_fc',
    'emoji_u_1_f_64_e_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_64_e_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_64_e_1_f_3_fd',
    'emoji_u_1_f_64_e_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_64_e_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_64_e_1_f_3_fe',
    'emoji_u_1_f_64_e_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_64_e_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_64_e_1_f_3_ff',
    'emoji_u_1_f_64_e_200_d_2640',
    'emoji_u_1_f_64_e_200_d_2642',
    'emoji_u_1_f_64_e',
    'emoji_u_1_f_64_f_1_f_3_fb',
    'emoji_u_1_f_64_f_1_f_3_fc',
    'emoji_u_1_f_64_f_1_f_3_fd',
    'emoji_u_1_f_64_f_1_f_3_fe',
    'emoji_u_1_f_64_f_1_f_3_ff',
    'emoji_u_1_f_64_f',
    'emoji_u_1_f_680',
    'emoji_u_1_f_681',
    'emoji_u_1_f_682',
    'emoji_u_1_f_683',
    'emoji_u_1_f_684',
    'emoji_u_1_f_685',
    'emoji_u_1_f_686',
    'emoji_u_1_f_687',
    'emoji_u_1_f_688',
    'emoji_u_1_f_689',
    'emoji_u_1_f_68_a',
    'emoji_u_1_f_68_b',
    'emoji_u_1_f_68_c',
    'emoji_u_1_f_68_d',
    'emoji_u_1_f_68_e',
    'emoji_u_1_f_68_f',
    'emoji_u_1_f_690',
    'emoji_u_1_f_691',
    'emoji_u_1_f_692',
    'emoji_u_1_f_693',
    'emoji_u_1_f_694',
    'emoji_u_1_f_695',
    'emoji_u_1_f_696',
    'emoji_u_1_f_697',
    'emoji_u_1_f_698',
    'emoji_u_1_f_699',
    'emoji_u_1_f_69_a',
    'emoji_u_1_f_69_b',
    'emoji_u_1_f_69_c',
    'emoji_u_1_f_69_d',
    'emoji_u_1_f_69_e',
    'emoji_u_1_f_69_f',
    'emoji_u_1_f_6_a_0',
    'emoji_u_1_f_6_a_1',
    'emoji_u_1_f_6_a_2',
    'emoji_u_1_f_6_a_3_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_6_a_3_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_6_a_3_1_f_3_fb',
    'emoji_u_1_f_6_a_3_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_6_a_3_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_6_a_3_1_f_3_fc',
    'emoji_u_1_f_6_a_3_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_6_a_3_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_6_a_3_1_f_3_fd',
    'emoji_u_1_f_6_a_3_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_6_a_3_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_6_a_3_1_f_3_fe',
    'emoji_u_1_f_6_a_3_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_6_a_3_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_6_a_3_1_f_3_ff',
    'emoji_u_1_f_6_a_3_200_d_2640',
    'emoji_u_1_f_6_a_3_200_d_2642',
    'emoji_u_1_f_6_a_3',
    'emoji_u_1_f_6_a_4',
    'emoji_u_1_f_6_a_5',
    'emoji_u_1_f_6_a_6',
    'emoji_u_1_f_6_a_7',
    'emoji_u_1_f_6_a_8',
    'emoji_u_1_f_6_a_9',
    'emoji_u_1_f_6_aa',
    'emoji_u_1_f_6_ab',
    'emoji_u_1_f_6_ac',
    'emoji_u_1_f_6_ad',
    'emoji_u_1_f_6_ae',
    'emoji_u_1_f_6_af',
    'emoji_u_1_f_6_b_0',
    'emoji_u_1_f_6_b_1',
    'emoji_u_1_f_6_b_2',
    'emoji_u_1_f_6_b_3',
    'emoji_u_1_f_6_b_4_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_6_b_4_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_6_b_4_1_f_3_fb',
    'emoji_u_1_f_6_b_4_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_6_b_4_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_6_b_4_1_f_3_fc',
    'emoji_u_1_f_6_b_4_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_6_b_4_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_6_b_4_1_f_3_fd',
    'emoji_u_1_f_6_b_4_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_6_b_4_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_6_b_4_1_f_3_fe',
    'emoji_u_1_f_6_b_4_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_6_b_4_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_6_b_4_1_f_3_ff',
    'emoji_u_1_f_6_b_4_200_d_2640',
    'emoji_u_1_f_6_b_4_200_d_2642',
    'emoji_u_1_f_6_b_4',
    'emoji_u_1_f_6_b_5_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_6_b_5_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_6_b_5_1_f_3_fb',
    'emoji_u_1_f_6_b_5_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_6_b_5_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_6_b_5_1_f_3_fc',
    'emoji_u_1_f_6_b_5_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_6_b_5_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_6_b_5_1_f_3_fd',
    'emoji_u_1_f_6_b_5_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_6_b_5_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_6_b_5_1_f_3_fe',
    'emoji_u_1_f_6_b_5_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_6_b_5_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_6_b_5_1_f_3_ff',
    'emoji_u_1_f_6_b_5_200_d_2640',
    'emoji_u_1_f_6_b_5_200_d_2642',
    'emoji_u_1_f_6_b_5',
    'emoji_u_1_f_6_b_6_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_6_b_6_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_6_b_6_1_f_3_fb',
    'emoji_u_1_f_6_b_6_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_6_b_6_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_6_b_6_1_f_3_fc',
    'emoji_u_1_f_6_b_6_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_6_b_6_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_6_b_6_1_f_3_fd',
    'emoji_u_1_f_6_b_6_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_6_b_6_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_6_b_6_1_f_3_fe',
    'emoji_u_1_f_6_b_6_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_6_b_6_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_6_b_6_1_f_3_ff',
    'emoji_u_1_f_6_b_6_200_d_2640',
    'emoji_u_1_f_6_b_6_200_d_2642',
    'emoji_u_1_f_6_b_6',
    'emoji_u_1_f_6_b_7',
    'emoji_u_1_f_6_b_8',
    'emoji_u_1_f_6_b_9',
    'emoji_u_1_f_6_ba',
    'emoji_u_1_f_6_bb',
    'emoji_u_1_f_6_bc',
    'emoji_u_1_f_6_bd',
    'emoji_u_1_f_6_be',
    'emoji_u_1_f_6_bf',
    'emoji_u_1_f_6_c_0_1_f_3_fb',
    'emoji_u_1_f_6_c_0_1_f_3_fc',
    'emoji_u_1_f_6_c_0_1_f_3_fd',
    'emoji_u_1_f_6_c_0_1_f_3_fe',
    'emoji_u_1_f_6_c_0_1_f_3_ff',
    'emoji_u_1_f_6_c_0',
    'emoji_u_1_f_6_c_1',
    'emoji_u_1_f_6_c_2',
    'emoji_u_1_f_6_c_3',
    'emoji_u_1_f_6_c_4',
    'emoji_u_1_f_6_c_5',
    'emoji_u_1_f_6_cb',
    'emoji_u_1_f_6_cc_1_f_3_fb',
    'emoji_u_1_f_6_cc_1_f_3_fc',
    'emoji_u_1_f_6_cc_1_f_3_fd',
    'emoji_u_1_f_6_cc_1_f_3_fe',
    'emoji_u_1_f_6_cc_1_f_3_ff',
    'emoji_u_1_f_6_cc',
    'emoji_u_1_f_6_cd',
    'emoji_u_1_f_6_ce',
    'emoji_u_1_f_6_cf',
    'emoji_u_1_f_6_d_0',
    'emoji_u_1_f_6_d_1',
    'emoji_u_1_f_6_d_2',
    'emoji_u_1_f_6_d_5',
    'emoji_u_1_f_6_e_0',
    'emoji_u_1_f_6_e_1',
    'emoji_u_1_f_6_e_2',
    'emoji_u_1_f_6_e_3',
    'emoji_u_1_f_6_e_4',
    'emoji_u_1_f_6_e_5',
    'emoji_u_1_f_6_e_9',
    'emoji_u_1_f_6_eb',
    'emoji_u_1_f_6_ec',
    'emoji_u_1_f_6_f_0',
    'emoji_u_1_f_6_f_3',
    'emoji_u_1_f_6_f_4',
    'emoji_u_1_f_6_f_5',
    'emoji_u_1_f_6_f_6',
    'emoji_u_1_f_6_f_7',
    'emoji_u_1_f_6_f_8',
    'emoji_u_1_f_6_f_9',
    'emoji_u_1_f_6_fa',
    'emoji_u_1_f_7_e_0',
    'emoji_u_1_f_7_e_1',
    'emoji_u_1_f_7_e_2',
    'emoji_u_1_f_7_e_3',
    'emoji_u_1_f_7_e_4',
    'emoji_u_1_f_7_e_5',
    'emoji_u_1_f_7_e_6',
    'emoji_u_1_f_7_e_7',
    'emoji_u_1_f_7_e_8',
    'emoji_u_1_f_7_e_9',
    'emoji_u_1_f_7_ea',
    'emoji_u_1_f_7_eb',
    'emoji_u_1_f_90_d',
    'emoji_u_1_f_90_e',
    'emoji_u_1_f_90_f_1_f_3_fb',
    'emoji_u_1_f_90_f_1_f_3_fc',
    'emoji_u_1_f_90_f_1_f_3_fd',
    'emoji_u_1_f_90_f_1_f_3_fe',
    'emoji_u_1_f_90_f_1_f_3_ff',
    'emoji_u_1_f_90_f',
    'emoji_u_1_f_910',
    'emoji_u_1_f_911',
    'emoji_u_1_f_912',
    'emoji_u_1_f_913',
    'emoji_u_1_f_914',
    'emoji_u_1_f_915',
    'emoji_u_1_f_916',
    'emoji_u_1_f_917',
    'emoji_u_1_f_918_1_f_3_fb',
    'emoji_u_1_f_918_1_f_3_fc',
    'emoji_u_1_f_918_1_f_3_fd',
    'emoji_u_1_f_918_1_f_3_fe',
    'emoji_u_1_f_918_1_f_3_ff',
    'emoji_u_1_f_918',
    'emoji_u_1_f_919_1_f_3_fb',
    'emoji_u_1_f_919_1_f_3_fc',
    'emoji_u_1_f_919_1_f_3_fd',
    'emoji_u_1_f_919_1_f_3_fe',
    'emoji_u_1_f_919_1_f_3_ff',
    'emoji_u_1_f_919',
    'emoji_u_1_f_91_a_1_f_3_fb',
    'emoji_u_1_f_91_a_1_f_3_fc',
    'emoji_u_1_f_91_a_1_f_3_fd',
    'emoji_u_1_f_91_a_1_f_3_fe',
    'emoji_u_1_f_91_a_1_f_3_ff',
    'emoji_u_1_f_91_a',
    'emoji_u_1_f_91_b_1_f_3_fb',
    'emoji_u_1_f_91_b_1_f_3_fc',
    'emoji_u_1_f_91_b_1_f_3_fd',
    'emoji_u_1_f_91_b_1_f_3_fe',
    'emoji_u_1_f_91_b_1_f_3_ff',
    'emoji_u_1_f_91_b',
    'emoji_u_1_f_91_c_1_f_3_fb',
    'emoji_u_1_f_91_c_1_f_3_fc',
    'emoji_u_1_f_91_c_1_f_3_fd',
    'emoji_u_1_f_91_c_1_f_3_fe',
    'emoji_u_1_f_91_c_1_f_3_ff',
    'emoji_u_1_f_91_c',
    'emoji_u_1_f_91_d_1_f_3_fb',
    'emoji_u_1_f_91_d_1_f_3_fc',
    'emoji_u_1_f_91_d_1_f_3_fd',
    'emoji_u_1_f_91_d_1_f_3_fe',
    'emoji_u_1_f_91_d_1_f_3_ff',
    'emoji_u_1_f_91_d',
    'emoji_u_1_f_91_e_1_f_3_fb',
    'emoji_u_1_f_91_e_1_f_3_fc',
    'emoji_u_1_f_91_e_1_f_3_fd',
    'emoji_u_1_f_91_e_1_f_3_fe',
    'emoji_u_1_f_91_e_1_f_3_ff',
    'emoji_u_1_f_91_e',
    'emoji_u_1_f_91_f_1_f_3_fb',
    'emoji_u_1_f_91_f_1_f_3_fc',
    'emoji_u_1_f_91_f_1_f_3_fd',
    'emoji_u_1_f_91_f_1_f_3_fe',
    'emoji_u_1_f_91_f_1_f_3_ff',
    'emoji_u_1_f_91_f',
    'emoji_u_1_f_920',
    'emoji_u_1_f_921',
    'emoji_u_1_f_922',
    'emoji_u_1_f_923',
    'emoji_u_1_f_924',
    'emoji_u_1_f_925',
    'emoji_u_1_f_926_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_926_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_926_1_f_3_fb',
    'emoji_u_1_f_926_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_926_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_926_1_f_3_fc',
    'emoji_u_1_f_926_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_926_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_926_1_f_3_fd',
    'emoji_u_1_f_926_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_926_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_926_1_f_3_fe',
    'emoji_u_1_f_926_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_926_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_926_1_f_3_ff',
    'emoji_u_1_f_926_200_d_2640',
    'emoji_u_1_f_926_200_d_2642',
    'emoji_u_1_f_926',
    'emoji_u_1_f_927',
    'emoji_u_1_f_928',
    'emoji_u_1_f_929',
    'emoji_u_1_f_92_a',
    'emoji_u_1_f_92_b',
    'emoji_u_1_f_92_c',
    'emoji_u_1_f_92_d',
    'emoji_u_1_f_92_e',
    'emoji_u_1_f_92_f',
    'emoji_u_1_f_930_1_f_3_fb',
    'emoji_u_1_f_930_1_f_3_fc',
    'emoji_u_1_f_930_1_f_3_fd',
    'emoji_u_1_f_930_1_f_3_fe',
    'emoji_u_1_f_930_1_f_3_ff',
    'emoji_u_1_f_930',
    'emoji_u_1_f_931_1_f_3_fb',
    'emoji_u_1_f_931_1_f_3_fc',
    'emoji_u_1_f_931_1_f_3_fd',
    'emoji_u_1_f_931_1_f_3_fe',
    'emoji_u_1_f_931_1_f_3_ff',
    'emoji_u_1_f_931',
    'emoji_u_1_f_932_1_f_3_fb',
    'emoji_u_1_f_932_1_f_3_fc',
    'emoji_u_1_f_932_1_f_3_fd',
    'emoji_u_1_f_932_1_f_3_fe',
    'emoji_u_1_f_932_1_f_3_ff',
    'emoji_u_1_f_932',
    'emoji_u_1_f_933_1_f_3_fb',
    'emoji_u_1_f_933_1_f_3_fc',
    'emoji_u_1_f_933_1_f_3_fd',
    'emoji_u_1_f_933_1_f_3_fe',
    'emoji_u_1_f_933_1_f_3_ff',
    'emoji_u_1_f_933',
    'emoji_u_1_f_934_1_f_3_fb',
    'emoji_u_1_f_934_1_f_3_fc',
    'emoji_u_1_f_934_1_f_3_fd',
    'emoji_u_1_f_934_1_f_3_fe',
    'emoji_u_1_f_934_1_f_3_ff',
    'emoji_u_1_f_934',
    'emoji_u_1_f_935_1_f_3_fb',
    'emoji_u_1_f_935_1_f_3_fc',
    'emoji_u_1_f_935_1_f_3_fd',
    'emoji_u_1_f_935_1_f_3_fe',
    'emoji_u_1_f_935_1_f_3_ff',
    'emoji_u_1_f_935',
    'emoji_u_1_f_936_1_f_3_fb',
    'emoji_u_1_f_936_1_f_3_fc',
    'emoji_u_1_f_936_1_f_3_fd',
    'emoji_u_1_f_936_1_f_3_fe',
    'emoji_u_1_f_936_1_f_3_ff',
    'emoji_u_1_f_936',
    'emoji_u_1_f_937_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_937_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_937_1_f_3_fb',
    'emoji_u_1_f_937_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_937_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_937_1_f_3_fc',
    'emoji_u_1_f_937_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_937_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_937_1_f_3_fd',
    'emoji_u_1_f_937_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_937_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_937_1_f_3_fe',
    'emoji_u_1_f_937_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_937_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_937_1_f_3_ff',
    'emoji_u_1_f_937_200_d_2640',
    'emoji_u_1_f_937_200_d_2642',
    'emoji_u_1_f_937',
    'emoji_u_1_f_938_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_938_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_938_1_f_3_fb',
    'emoji_u_1_f_938_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_938_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_938_1_f_3_fc',
    'emoji_u_1_f_938_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_938_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_938_1_f_3_fd',
    'emoji_u_1_f_938_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_938_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_938_1_f_3_fe',
    'emoji_u_1_f_938_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_938_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_938_1_f_3_ff',
    'emoji_u_1_f_938_200_d_2640',
    'emoji_u_1_f_938_200_d_2642',
    'emoji_u_1_f_938',
    'emoji_u_1_f_939_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_939_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_939_1_f_3_fb',
    'emoji_u_1_f_939_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_939_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_939_1_f_3_fc',
    'emoji_u_1_f_939_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_939_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_939_1_f_3_fd',
    'emoji_u_1_f_939_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_939_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_939_1_f_3_fe',
    'emoji_u_1_f_939_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_939_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_939_1_f_3_ff',
    'emoji_u_1_f_939_200_d_2640',
    'emoji_u_1_f_939_200_d_2642',
    'emoji_u_1_f_939',
    'emoji_u_1_f_93_a',
    'emoji_u_1_f_93_c_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_93_c_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_93_c_1_f_3_fb',
    'emoji_u_1_f_93_c_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_93_c_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_93_c_1_f_3_fc',
    'emoji_u_1_f_93_c_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_93_c_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_93_c_1_f_3_fd',
    'emoji_u_1_f_93_c_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_93_c_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_93_c_1_f_3_fe',
    'emoji_u_1_f_93_c_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_93_c_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_93_c_1_f_3_ff',
    'emoji_u_1_f_93_c_200_d_2640',
    'emoji_u_1_f_93_c_200_d_2642',
    'emoji_u_1_f_93_c',
    'emoji_u_1_f_93_d_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_93_d_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_93_d_1_f_3_fb',
    'emoji_u_1_f_93_d_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_93_d_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_93_d_1_f_3_fc',
    'emoji_u_1_f_93_d_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_93_d_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_93_d_1_f_3_fd',
    'emoji_u_1_f_93_d_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_93_d_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_93_d_1_f_3_fe',
    'emoji_u_1_f_93_d_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_93_d_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_93_d_1_f_3_ff',
    'emoji_u_1_f_93_d_200_d_2640',
    'emoji_u_1_f_93_d_200_d_2642',
    'emoji_u_1_f_93_d',
    'emoji_u_1_f_93_e_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_93_e_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_93_e_1_f_3_fb',
    'emoji_u_1_f_93_e_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_93_e_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_93_e_1_f_3_fc',
    'emoji_u_1_f_93_e_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_93_e_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_93_e_1_f_3_fd',
    'emoji_u_1_f_93_e_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_93_e_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_93_e_1_f_3_fe',
    'emoji_u_1_f_93_e_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_93_e_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_93_e_1_f_3_ff',
    'emoji_u_1_f_93_e_200_d_2640',
    'emoji_u_1_f_93_e_200_d_2642',
    'emoji_u_1_f_93_e',
    'emoji_u_1_f_93_f',
    'emoji_u_1_f_940',
    'emoji_u_1_f_941',
    'emoji_u_1_f_942',
    'emoji_u_1_f_943',
    'emoji_u_1_f_944',
    'emoji_u_1_f_945',
    'emoji_u_1_f_947',
    'emoji_u_1_f_948',
    'emoji_u_1_f_949',
    'emoji_u_1_f_94_a',
    'emoji_u_1_f_94_b',
    'emoji_u_1_f_94_c',
    'emoji_u_1_f_94_d',
    'emoji_u_1_f_94_e',
    'emoji_u_1_f_94_f',
    'emoji_u_1_f_950',
    'emoji_u_1_f_951',
    'emoji_u_1_f_952',
    'emoji_u_1_f_953',
    'emoji_u_1_f_954',
    'emoji_u_1_f_955',
    'emoji_u_1_f_956',
    'emoji_u_1_f_957',
    'emoji_u_1_f_958',
    'emoji_u_1_f_959',
    'emoji_u_1_f_95_a',
    'emoji_u_1_f_95_b',
    'emoji_u_1_f_95_c',
    'emoji_u_1_f_95_d',
    'emoji_u_1_f_95_e',
    'emoji_u_1_f_95_f',
    'emoji_u_1_f_960',
    'emoji_u_1_f_961',
    'emoji_u_1_f_962',
    'emoji_u_1_f_963',
    'emoji_u_1_f_964',
    'emoji_u_1_f_965',
    'emoji_u_1_f_966',
    'emoji_u_1_f_967',
    'emoji_u_1_f_968',
    'emoji_u_1_f_969',
    'emoji_u_1_f_96_a',
    'emoji_u_1_f_96_b',
    'emoji_u_1_f_96_c',
    'emoji_u_1_f_96_d',
    'emoji_u_1_f_96_e',
    'emoji_u_1_f_96_f',
    'emoji_u_1_f_970',
    'emoji_u_1_f_971',
    'emoji_u_1_f_973',
    'emoji_u_1_f_974',
    'emoji_u_1_f_975',
    'emoji_u_1_f_976',
    'emoji_u_1_f_97_a',
    'emoji_u_1_f_97_b',
    'emoji_u_1_f_97_c',
    'emoji_u_1_f_97_d',
    'emoji_u_1_f_97_e',
    'emoji_u_1_f_97_f',
    'emoji_u_1_f_980',
    'emoji_u_1_f_981',
    'emoji_u_1_f_982',
    'emoji_u_1_f_983',
    'emoji_u_1_f_984',
    'emoji_u_1_f_985',
    'emoji_u_1_f_986',
    'emoji_u_1_f_987',
    'emoji_u_1_f_988',
    'emoji_u_1_f_989',
    'emoji_u_1_f_98_a',
    'emoji_u_1_f_98_b',
    'emoji_u_1_f_98_c',
    'emoji_u_1_f_98_d',
    'emoji_u_1_f_98_e',
    'emoji_u_1_f_98_f',
    'emoji_u_1_f_990',
    'emoji_u_1_f_991',
    'emoji_u_1_f_992',
    'emoji_u_1_f_993',
    'emoji_u_1_f_994',
    'emoji_u_1_f_995',
    'emoji_u_1_f_996',
    'emoji_u_1_f_997',
    'emoji_u_1_f_998',
    'emoji_u_1_f_999',
    'emoji_u_1_f_99_a',
    'emoji_u_1_f_99_b',
    'emoji_u_1_f_99_c',
    'emoji_u_1_f_99_d',
    'emoji_u_1_f_99_e',
    'emoji_u_1_f_99_f',
    'emoji_u_1_f_9_a_0',
    'emoji_u_1_f_9_a_1',
    'emoji_u_1_f_9_a_2',
    'emoji_u_1_f_9_a_5',
    'emoji_u_1_f_9_a_6',
    'emoji_u_1_f_9_a_7',
    'emoji_u_1_f_9_a_8',
    'emoji_u_1_f_9_a_9',
    'emoji_u_1_f_9_aa',
    'emoji_u_1_f_9_ae',
    'emoji_u_1_f_9_af',
    'emoji_u_1_f_9_b_0',
    'emoji_u_1_f_9_b_1',
    'emoji_u_1_f_9_b_2',
    'emoji_u_1_f_9_b_3',
    'emoji_u_1_f_9_b_4',
    'emoji_u_1_f_9_b_5_1_f_3_fb',
    'emoji_u_1_f_9_b_5_1_f_3_fc',
    'emoji_u_1_f_9_b_5_1_f_3_fd',
    'emoji_u_1_f_9_b_5_1_f_3_fe',
    'emoji_u_1_f_9_b_5_1_f_3_ff',
    'emoji_u_1_f_9_b_5',
    'emoji_u_1_f_9_b_6_1_f_3_fb',
    'emoji_u_1_f_9_b_6_1_f_3_fc',
    'emoji_u_1_f_9_b_6_1_f_3_fd',
    'emoji_u_1_f_9_b_6_1_f_3_fe',
    'emoji_u_1_f_9_b_6_1_f_3_ff',
    'emoji_u_1_f_9_b_6',
    'emoji_u_1_f_9_b_7',
    'emoji_u_1_f_9_b_8_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_9_b_8_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_9_b_8_1_f_3_fb',
    'emoji_u_1_f_9_b_8_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_9_b_8_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_9_b_8_1_f_3_fc',
    'emoji_u_1_f_9_b_8_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_9_b_8_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_9_b_8_1_f_3_fd',
    'emoji_u_1_f_9_b_8_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_9_b_8_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_9_b_8_1_f_3_fe',
    'emoji_u_1_f_9_b_8_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_9_b_8_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_9_b_8_1_f_3_ff',
    'emoji_u_1_f_9_b_8_200_d_2640',
    'emoji_u_1_f_9_b_8_200_d_2642',
    'emoji_u_1_f_9_b_8',
    'emoji_u_1_f_9_b_9_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_9_b_9_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_9_b_9_1_f_3_fb',
    'emoji_u_1_f_9_b_9_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_9_b_9_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_9_b_9_1_f_3_fc',
    'emoji_u_1_f_9_b_9_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_9_b_9_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_9_b_9_1_f_3_fd',
    'emoji_u_1_f_9_b_9_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_9_b_9_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_9_b_9_1_f_3_fe',
    'emoji_u_1_f_9_b_9_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_9_b_9_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_9_b_9_1_f_3_ff',
    'emoji_u_1_f_9_b_9_200_d_2640',
    'emoji_u_1_f_9_b_9_200_d_2642',
    'emoji_u_1_f_9_b_9',
    'emoji_u_1_f_9_ba',
    'emoji_u_1_f_9_bb_1_f_3_fb',
    'emoji_u_1_f_9_bb_1_f_3_fc',
    'emoji_u_1_f_9_bb_1_f_3_fd',
    'emoji_u_1_f_9_bb_1_f_3_fe',
    'emoji_u_1_f_9_bb_1_f_3_ff',
    'emoji_u_1_f_9_bb',
    'emoji_u_1_f_9_bc',
    'emoji_u_1_f_9_bd',
    'emoji_u_1_f_9_be',
    'emoji_u_1_f_9_bf',
    'emoji_u_1_f_9_c_0',
    'emoji_u_1_f_9_c_1',
    'emoji_u_1_f_9_c_2',
    'emoji_u_1_f_9_c_3',
    'emoji_u_1_f_9_c_4',
    'emoji_u_1_f_9_c_5',
    'emoji_u_1_f_9_c_6',
    'emoji_u_1_f_9_c_7',
    'emoji_u_1_f_9_c_8',
    'emoji_u_1_f_9_c_9',
    'emoji_u_1_f_9_ca',
    'emoji_u_1_f_9_cd_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_9_cd_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_9_cd_1_f_3_fb',
    'emoji_u_1_f_9_cd_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_9_cd_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_9_cd_1_f_3_fc',
    'emoji_u_1_f_9_cd_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_9_cd_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_9_cd_1_f_3_fd',
    'emoji_u_1_f_9_cd_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_9_cd_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_9_cd_1_f_3_fe',
    'emoji_u_1_f_9_cd_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_9_cd_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_9_cd_1_f_3_ff',
    'emoji_u_1_f_9_cd_200_d_2640',
    'emoji_u_1_f_9_cd_200_d_2642',
    'emoji_u_1_f_9_cd',
    'emoji_u_1_f_9_ce_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_9_ce_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_9_ce_1_f_3_fb',
    'emoji_u_1_f_9_ce_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_9_ce_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_9_ce_1_f_3_fc',
    'emoji_u_1_f_9_ce_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_9_ce_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_9_ce_1_f_3_fd',
    'emoji_u_1_f_9_ce_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_9_ce_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_9_ce_1_f_3_fe',
    'emoji_u_1_f_9_ce_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_9_ce_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_9_ce_1_f_3_ff',
    'emoji_u_1_f_9_ce_200_d_2640',
    'emoji_u_1_f_9_ce_200_d_2642',
    'emoji_u_1_f_9_ce',
    'emoji_u_1_f_9_cf_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_9_cf_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_9_cf_1_f_3_fb',
    'emoji_u_1_f_9_cf_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_9_cf_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_9_cf_1_f_3_fc',
    'emoji_u_1_f_9_cf_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_9_cf_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_9_cf_1_f_3_fd',
    'emoji_u_1_f_9_cf_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_9_cf_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_9_cf_1_f_3_fe',
    'emoji_u_1_f_9_cf_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_9_cf_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_9_cf_1_f_3_ff',
    'emoji_u_1_f_9_cf_200_d_2640',
    'emoji_u_1_f_9_cf_200_d_2642',
    'emoji_u_1_f_9_cf',
    'emoji_u_1_f_9_d_0',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_33_e',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_373',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_393',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_3_a_4',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_3_a_8',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_3_eb',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_3_ed',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_4_bb',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_4_bc',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_527',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_52_c',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_680',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_692',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fb',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fc',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fd',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fe',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_ff',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_9_af',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_9_b_0',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_9_b_1',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_9_b_2',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_9_b_3',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_9_bc',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_1_f_9_bd',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_2695',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_2696',
    'emoji_u_1_f_9_d_1_1_f_3_fb_200_d_2708',
    'emoji_u_1_f_9_d_1_1_f_3_fb',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_33_e',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_373',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_393',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_3_a_4',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_3_a_8',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_3_eb',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_3_ed',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_4_bb',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_4_bc',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_527',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_52_c',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_680',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_692',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fb',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fc',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fd',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fe',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_ff',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_9_af',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_9_b_0',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_9_b_1',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_9_b_2',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_9_b_3',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_9_bc',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_1_f_9_bd',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_2695',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_2696',
    'emoji_u_1_f_9_d_1_1_f_3_fc_200_d_2708',
    'emoji_u_1_f_9_d_1_1_f_3_fc',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_33_e',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_373',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_393',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_3_a_4',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_3_a_8',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_3_eb',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_3_ed',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_4_bb',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_4_bc',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_527',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_52_c',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_680',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_692',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fb',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fc',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fd',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fe',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_ff',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_9_af',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_9_b_0',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_9_b_1',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_9_b_2',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_9_b_3',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_9_bc',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_1_f_9_bd',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_2695',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_2696',
    'emoji_u_1_f_9_d_1_1_f_3_fd_200_d_2708',
    'emoji_u_1_f_9_d_1_1_f_3_fd',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_33_e',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_373',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_393',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_3_a_4',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_3_a_8',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_3_eb',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_3_ed',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_4_bb',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_4_bc',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_527',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_52_c',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_680',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_692',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fb',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fc',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fd',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fe',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_ff',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_9_af',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_9_b_0',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_9_b_1',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_9_b_2',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_9_b_3',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_9_bc',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_1_f_9_bd',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_2695',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_2696',
    'emoji_u_1_f_9_d_1_1_f_3_fe_200_d_2708',
    'emoji_u_1_f_9_d_1_1_f_3_fe',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_33_e',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_373',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_393',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_3_a_4',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_3_a_8',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_3_eb',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_3_ed',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_4_bb',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_4_bc',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_527',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_52_c',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_680',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_692',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fb',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fc',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fd',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_fe',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_91_d_200_d_1_f_9_d_1_1_f_3_ff',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_9_af',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_9_b_0',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_9_b_1',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_9_b_2',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_9_b_3',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_9_bc',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_1_f_9_bd',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_2695',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_2696',
    'emoji_u_1_f_9_d_1_1_f_3_ff_200_d_2708',
    'emoji_u_1_f_9_d_1_1_f_3_ff',
    'emoji_u_1_f_9_d_1_200_d_1_f_33_e',
    'emoji_u_1_f_9_d_1_200_d_1_f_373',
    'emoji_u_1_f_9_d_1_200_d_1_f_393',
    'emoji_u_1_f_9_d_1_200_d_1_f_3_a_4',
    'emoji_u_1_f_9_d_1_200_d_1_f_3_a_8',
    'emoji_u_1_f_9_d_1_200_d_1_f_3_eb',
    'emoji_u_1_f_9_d_1_200_d_1_f_3_ed',
    'emoji_u_1_f_9_d_1_200_d_1_f_4_bb',
    'emoji_u_1_f_9_d_1_200_d_1_f_4_bc',
    'emoji_u_1_f_9_d_1_200_d_1_f_527',
    'emoji_u_1_f_9_d_1_200_d_1_f_52_c',
    'emoji_u_1_f_9_d_1_200_d_1_f_680',
    'emoji_u_1_f_9_d_1_200_d_1_f_692',
    'emoji_u_1_f_9_d_1_200_d_1_f_91_d_200_d_1_f_9_d_1',
    'emoji_u_1_f_9_d_1_200_d_1_f_9_af',
    'emoji_u_1_f_9_d_1_200_d_1_f_9_b_0',
    'emoji_u_1_f_9_d_1_200_d_1_f_9_b_1',
    'emoji_u_1_f_9_d_1_200_d_1_f_9_b_2',
    'emoji_u_1_f_9_d_1_200_d_1_f_9_b_3',
    'emoji_u_1_f_9_d_1_200_d_1_f_9_bc',
    'emoji_u_1_f_9_d_1_200_d_1_f_9_bd',
    'emoji_u_1_f_9_d_1_200_d_2695',
    'emoji_u_1_f_9_d_1_200_d_2696',
    'emoji_u_1_f_9_d_1_200_d_2708',
    'emoji_u_1_f_9_d_1',
    'emoji_u_1_f_9_d_2_1_f_3_fb',
    'emoji_u_1_f_9_d_2_1_f_3_fc',
    'emoji_u_1_f_9_d_2_1_f_3_fd',
    'emoji_u_1_f_9_d_2_1_f_3_fe',
    'emoji_u_1_f_9_d_2_1_f_3_ff',
    'emoji_u_1_f_9_d_2',
    'emoji_u_1_f_9_d_3_1_f_3_fb',
    'emoji_u_1_f_9_d_3_1_f_3_fc',
    'emoji_u_1_f_9_d_3_1_f_3_fd',
    'emoji_u_1_f_9_d_3_1_f_3_fe',
    'emoji_u_1_f_9_d_3_1_f_3_ff',
    'emoji_u_1_f_9_d_3',
    'emoji_u_1_f_9_d_4_1_f_3_fb',
    'emoji_u_1_f_9_d_4_1_f_3_fc',
    'emoji_u_1_f_9_d_4_1_f_3_fd',
    'emoji_u_1_f_9_d_4_1_f_3_fe',
    'emoji_u_1_f_9_d_4_1_f_3_ff',
    'emoji_u_1_f_9_d_4',
    'emoji_u_1_f_9_d_5_1_f_3_fb',
    'emoji_u_1_f_9_d_5_1_f_3_fc',
    'emoji_u_1_f_9_d_5_1_f_3_fd',
    'emoji_u_1_f_9_d_5_1_f_3_fe',
    'emoji_u_1_f_9_d_5_1_f_3_ff',
    'emoji_u_1_f_9_d_5',
    'emoji_u_1_f_9_d_6_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_9_d_6_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_9_d_6_1_f_3_fb',
    'emoji_u_1_f_9_d_6_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_9_d_6_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_9_d_6_1_f_3_fc',
    'emoji_u_1_f_9_d_6_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_9_d_6_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_9_d_6_1_f_3_fd',
    'emoji_u_1_f_9_d_6_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_9_d_6_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_9_d_6_1_f_3_fe',
    'emoji_u_1_f_9_d_6_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_9_d_6_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_9_d_6_1_f_3_ff',
    'emoji_u_1_f_9_d_6_200_d_2640',
    'emoji_u_1_f_9_d_6_200_d_2642',
    'emoji_u_1_f_9_d_6',
    'emoji_u_1_f_9_d_7_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_9_d_7_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_9_d_7_1_f_3_fb',
    'emoji_u_1_f_9_d_7_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_9_d_7_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_9_d_7_1_f_3_fc',
    'emoji_u_1_f_9_d_7_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_9_d_7_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_9_d_7_1_f_3_fd',
    'emoji_u_1_f_9_d_7_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_9_d_7_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_9_d_7_1_f_3_fe',
    'emoji_u_1_f_9_d_7_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_9_d_7_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_9_d_7_1_f_3_ff',
    'emoji_u_1_f_9_d_7_200_d_2640',
    'emoji_u_1_f_9_d_7_200_d_2642',
    'emoji_u_1_f_9_d_7',
    'emoji_u_1_f_9_d_8_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_9_d_8_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_9_d_8_1_f_3_fb',
    'emoji_u_1_f_9_d_8_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_9_d_8_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_9_d_8_1_f_3_fc',
    'emoji_u_1_f_9_d_8_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_9_d_8_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_9_d_8_1_f_3_fd',
    'emoji_u_1_f_9_d_8_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_9_d_8_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_9_d_8_1_f_3_fe',
    'emoji_u_1_f_9_d_8_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_9_d_8_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_9_d_8_1_f_3_ff',
    'emoji_u_1_f_9_d_8_200_d_2640',
    'emoji_u_1_f_9_d_8_200_d_2642',
    'emoji_u_1_f_9_d_8',
    'emoji_u_1_f_9_d_9_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_9_d_9_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_9_d_9_1_f_3_fb',
    'emoji_u_1_f_9_d_9_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_9_d_9_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_9_d_9_1_f_3_fc',
    'emoji_u_1_f_9_d_9_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_9_d_9_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_9_d_9_1_f_3_fd',
    'emoji_u_1_f_9_d_9_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_9_d_9_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_9_d_9_1_f_3_fe',
    'emoji_u_1_f_9_d_9_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_9_d_9_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_9_d_9_1_f_3_ff',
    'emoji_u_1_f_9_d_9_200_d_2640',
    'emoji_u_1_f_9_d_9_200_d_2642',
    'emoji_u_1_f_9_d_9',
    'emoji_u_1_f_9_da_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_9_da_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_9_da_1_f_3_fb',
    'emoji_u_1_f_9_da_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_9_da_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_9_da_1_f_3_fc',
    'emoji_u_1_f_9_da_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_9_da_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_9_da_1_f_3_fd',
    'emoji_u_1_f_9_da_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_9_da_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_9_da_1_f_3_fe',
    'emoji_u_1_f_9_da_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_9_da_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_9_da_1_f_3_ff',
    'emoji_u_1_f_9_da_200_d_2640',
    'emoji_u_1_f_9_da_200_d_2642',
    'emoji_u_1_f_9_da',
    'emoji_u_1_f_9_db_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_9_db_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_9_db_1_f_3_fb',
    'emoji_u_1_f_9_db_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_9_db_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_9_db_1_f_3_fc',
    'emoji_u_1_f_9_db_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_9_db_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_9_db_1_f_3_fd',
    'emoji_u_1_f_9_db_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_9_db_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_9_db_1_f_3_fe',
    'emoji_u_1_f_9_db_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_9_db_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_9_db_1_f_3_ff',
    'emoji_u_1_f_9_db_200_d_2640',
    'emoji_u_1_f_9_db_200_d_2642',
    'emoji_u_1_f_9_db',
    'emoji_u_1_f_9_dc_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_9_dc_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_9_dc_1_f_3_fb',
    'emoji_u_1_f_9_dc_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_9_dc_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_9_dc_1_f_3_fc',
    'emoji_u_1_f_9_dc_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_9_dc_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_9_dc_1_f_3_fd',
    'emoji_u_1_f_9_dc_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_9_dc_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_9_dc_1_f_3_fe',
    'emoji_u_1_f_9_dc_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_9_dc_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_9_dc_1_f_3_ff',
    'emoji_u_1_f_9_dc_200_d_2640',
    'emoji_u_1_f_9_dc_200_d_2642',
    'emoji_u_1_f_9_dc',
    'emoji_u_1_f_9_dd_1_f_3_fb_200_d_2640',
    'emoji_u_1_f_9_dd_1_f_3_fb_200_d_2642',
    'emoji_u_1_f_9_dd_1_f_3_fb',
    'emoji_u_1_f_9_dd_1_f_3_fc_200_d_2640',
    'emoji_u_1_f_9_dd_1_f_3_fc_200_d_2642',
    'emoji_u_1_f_9_dd_1_f_3_fc',
    'emoji_u_1_f_9_dd_1_f_3_fd_200_d_2640',
    'emoji_u_1_f_9_dd_1_f_3_fd_200_d_2642',
    'emoji_u_1_f_9_dd_1_f_3_fd',
    'emoji_u_1_f_9_dd_1_f_3_fe_200_d_2640',
    'emoji_u_1_f_9_dd_1_f_3_fe_200_d_2642',
    'emoji_u_1_f_9_dd_1_f_3_fe',
    'emoji_u_1_f_9_dd_1_f_3_ff_200_d_2640',
    'emoji_u_1_f_9_dd_1_f_3_ff_200_d_2642',
    'emoji_u_1_f_9_dd_1_f_3_ff',
    'emoji_u_1_f_9_dd_200_d_2640',
    'emoji_u_1_f_9_dd_200_d_2642',
    'emoji_u_1_f_9_dd',
    'emoji_u_1_f_9_de_200_d_2640',
    'emoji_u_1_f_9_de_200_d_2642',
    'emoji_u_1_f_9_de',
    'emoji_u_1_f_9_df_200_d_2640',
    'emoji_u_1_f_9_df_200_d_2642',
    'emoji_u_1_f_9_df',
    'emoji_u_1_f_9_e_0',
    'emoji_u_1_f_9_e_1',
    'emoji_u_1_f_9_e_2',
    'emoji_u_1_f_9_e_3',
    'emoji_u_1_f_9_e_4',
    'emoji_u_1_f_9_e_5',
    'emoji_u_1_f_9_e_6',
    'emoji_u_1_f_9_e_7',
    'emoji_u_1_f_9_e_8',
    'emoji_u_1_f_9_e_9',
    'emoji_u_1_f_9_ea',
    'emoji_u_1_f_9_eb',
    'emoji_u_1_f_9_ec',
    'emoji_u_1_f_9_ed',
    'emoji_u_1_f_9_ee',
    'emoji_u_1_f_9_ef',
    'emoji_u_1_f_9_f_0',
    'emoji_u_1_f_9_f_1',
    'emoji_u_1_f_9_f_2',
    'emoji_u_1_f_9_f_3',
    'emoji_u_1_f_9_f_4',
    'emoji_u_1_f_9_f_5',
    'emoji_u_1_f_9_f_6',
    'emoji_u_1_f_9_f_7',
    'emoji_u_1_f_9_f_8',
    'emoji_u_1_f_9_f_9',
    'emoji_u_1_f_9_fa',
    'emoji_u_1_f_9_fb',
    'emoji_u_1_f_9_fc',
    'emoji_u_1_f_9_fd',
    'emoji_u_1_f_9_fe',
    'emoji_u_1_f_9_ff',
    'emoji_u_1_fa_70',
    'emoji_u_1_fa_71',
    'emoji_u_1_fa_72',
    'emoji_u_1_fa_73',
    'emoji_u_1_fa_78',
    'emoji_u_1_fa_79',
    'emoji_u_1_fa_7_a',
    'emoji_u_1_fa_80',
    'emoji_u_1_fa_81',
    'emoji_u_1_fa_82',
    'emoji_u_1_fa_90',
    'emoji_u_1_fa_91',
    'emoji_u_1_fa_92',
    'emoji_u_1_fa_93',
    'emoji_u_1_fa_94',
    'emoji_u_1_fa_95',
    'emoji_u_203_c',
    'emoji_u_2049',
    'emoji_u_20_e_3',
    'emoji_u_2122',
    'emoji_u_2139',
    'emoji_u_2194',
    'emoji_u_2195',
    'emoji_u_2196',
    'emoji_u_2197',
    'emoji_u_2198',
    'emoji_u_2199',
    'emoji_u_21_a_9',
    'emoji_u_21_aa',
    'emoji_u_231_a',
    'emoji_u_231_b',
    'emoji_u_2328',
    'emoji_u_23_cf',
    'emoji_u_23_e_9',
    'emoji_u_23_ea',
    'emoji_u_23_eb',
    'emoji_u_23_ec',
    'emoji_u_23_ed',
    'emoji_u_23_ee',
    'emoji_u_23_ef',
    'emoji_u_23_f_0',
    'emoji_u_23_f_1',
    'emoji_u_23_f_2',
    'emoji_u_23_f_3',
    'emoji_u_23_f_8',
    'emoji_u_23_f_9',
    'emoji_u_23_fa',
    'emoji_u_24_c_2',
    'emoji_u_25_aa',
    'emoji_u_25_ab',
    'emoji_u_25_b_6',
    'emoji_u_25_c_0',
    'emoji_u_25_fb',
    'emoji_u_25_fc',
    'emoji_u_25_fd',
    'emoji_u_25_fe',
    'emoji_u_2600',
    'emoji_u_2601',
    'emoji_u_2602',
    'emoji_u_2603',
    'emoji_u_2604',
    'emoji_u_260_e',
    'emoji_u_2611',
    'emoji_u_2614',
    'emoji_u_2615',
    'emoji_u_2618',
    'emoji_u_261_d_1_f_3_fb',
    'emoji_u_261_d_1_f_3_fc',
    'emoji_u_261_d_1_f_3_fd',
    'emoji_u_261_d_1_f_3_fe',
    'emoji_u_261_d_1_f_3_ff',
    'emoji_u_261_d',
    'emoji_u_2620',
    'emoji_u_2622',
    'emoji_u_2623',
    'emoji_u_2626',
    'emoji_u_262_a',
    'emoji_u_262_e',
    'emoji_u_262_f',
    'emoji_u_2638',
    'emoji_u_2639',
    'emoji_u_263_a',
    'emoji_u_2640',
    'emoji_u_2642',
    'emoji_u_2648',
    'emoji_u_2649',
    'emoji_u_264_a',
    'emoji_u_264_b',
    'emoji_u_264_c',
    'emoji_u_264_d',
    'emoji_u_264_e',
    'emoji_u_264_f',
    'emoji_u_2650',
    'emoji_u_2651',
    'emoji_u_2652',
    'emoji_u_2653',
    'emoji_u_265_f',
    'emoji_u_2660',
    'emoji_u_2663',
    'emoji_u_2665',
    'emoji_u_2666',
    'emoji_u_2668',
    'emoji_u_267_b',
    'emoji_u_267_e',
    'emoji_u_267_f',
    'emoji_u_2692',
    'emoji_u_2693',
    'emoji_u_2694',
    'emoji_u_2695',
    'emoji_u_2696',
    'emoji_u_2697',
    'emoji_u_2699',
    'emoji_u_269_b',
    'emoji_u_269_c',
    'emoji_u_26_a_0',
    'emoji_u_26_a_1',
    'emoji_u_26_aa',
    'emoji_u_26_ab',
    'emoji_u_26_b_0',
    'emoji_u_26_b_1',
    'emoji_u_26_bd',
    'emoji_u_26_be',
    'emoji_u_26_c_4',
    'emoji_u_26_c_5',
    'emoji_u_26_c_8',
    'emoji_u_26_ce',
    'emoji_u_26_cf',
    'emoji_u_26_d_1',
    'emoji_u_26_d_3',
    'emoji_u_26_d_4',
    'emoji_u_26_e_9',
    'emoji_u_26_ea',
    'emoji_u_26_f_0',
    'emoji_u_26_f_1',
    'emoji_u_26_f_2',
    'emoji_u_26_f_3',
    'emoji_u_26_f_4',
    'emoji_u_26_f_5',
    'emoji_u_26_f_7',
    'emoji_u_26_f_8',
    'emoji_u_26_f_9_1_f_3_fb_200_d_2640',
    'emoji_u_26_f_9_1_f_3_fb_200_d_2642',
    'emoji_u_26_f_9_1_f_3_fb',
    'emoji_u_26_f_9_1_f_3_fc_200_d_2640',
    'emoji_u_26_f_9_1_f_3_fc_200_d_2642',
    'emoji_u_26_f_9_1_f_3_fc',
    'emoji_u_26_f_9_1_f_3_fd_200_d_2640',
    'emoji_u_26_f_9_1_f_3_fd_200_d_2642',
    'emoji_u_26_f_9_1_f_3_fd',
    'emoji_u_26_f_9_1_f_3_fe_200_d_2640',
    'emoji_u_26_f_9_1_f_3_fe_200_d_2642',
    'emoji_u_26_f_9_1_f_3_fe',
    'emoji_u_26_f_9_1_f_3_ff_200_d_2640',
    'emoji_u_26_f_9_1_f_3_ff_200_d_2642',
    'emoji_u_26_f_9_1_f_3_ff',
    'emoji_u_26_f_9_200_d_2640',
    'emoji_u_26_f_9_200_d_2642',
    'emoji_u_26_f_9',
    'emoji_u_26_fa',
    'emoji_u_26_fd',
    'emoji_u_2702',
    'emoji_u_2705',
    'emoji_u_2708',
    'emoji_u_2709',
    'emoji_u_270_a_1_f_3_fb',
    'emoji_u_270_a_1_f_3_fc',
    'emoji_u_270_a_1_f_3_fd',
    'emoji_u_270_a_1_f_3_fe',
    'emoji_u_270_a_1_f_3_ff',
    'emoji_u_270_a',
    'emoji_u_270_b_1_f_3_fb',
    'emoji_u_270_b_1_f_3_fc',
    'emoji_u_270_b_1_f_3_fd',
    'emoji_u_270_b_1_f_3_fe',
    'emoji_u_270_b_1_f_3_ff',
    'emoji_u_270_b',
    'emoji_u_270_c_1_f_3_fb',
    'emoji_u_270_c_1_f_3_fc',
    'emoji_u_270_c_1_f_3_fd',
    'emoji_u_270_c_1_f_3_fe',
    'emoji_u_270_c_1_f_3_ff',
    'emoji_u_270_c',
    'emoji_u_270_d_1_f_3_fb',
    'emoji_u_270_d_1_f_3_fc',
    'emoji_u_270_d_1_f_3_fd',
    'emoji_u_270_d_1_f_3_fe',
    'emoji_u_270_d_1_f_3_ff',
    'emoji_u_270_d',
    'emoji_u_270_f',
    'emoji_u_2712',
    'emoji_u_2714',
    'emoji_u_2716',
    'emoji_u_271_d',
    'emoji_u_2721',
    'emoji_u_2728',
    'emoji_u_2733',
    'emoji_u_2734',
    'emoji_u_2744',
    'emoji_u_2747',
    'emoji_u_274_c',
    'emoji_u_274_e',
    'emoji_u_2753',
    'emoji_u_2754',
    'emoji_u_2755',
    'emoji_u_2757',
    'emoji_u_2763',
    'emoji_u_2764',
    'emoji_u_2795',
    'emoji_u_2796',
    'emoji_u_2797',
    'emoji_u_27_a_1',
    'emoji_u_27_b_0',
    'emoji_u_27_bf',
    'emoji_u_2934',
    'emoji_u_2935',
    'emoji_u_2_b_05',
    'emoji_u_2_b_06',
    'emoji_u_2_b_07',
    'emoji_u_2_b_1_b',
    'emoji_u_2_b_1_c',
    'emoji_u_2_b_50',
    'emoji_u_2_b_55',
    'emoji_u_3030',
    'emoji_u_303_d',
    'emoji_u_3297',
    'emoji_u_3299',
    'emoji_ufe_82_b',
  ];

  ngOnInit(): void {
    this.term.valueChanges.pipe(
      throttleTime(64),
      filter((term) => term && term.length > 0),
      map((term) => this.icons.filter((element) => element.indexOf(term) !== -1)),
    ).subscribe((results) => {
      this.results = results;
      }
    );
  }

  clear() {
    this.results.length = 0;
  }
}
